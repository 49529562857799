<template>
  <MTimePicker
    :value="innerValue"
    v-bind="$attrs"
    @change="handleChange"
    v-on="listeners"
  />
</template>

<script>
import Moment from 'moment'
export default {
  name: 'TimePickerCalender',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    hourKey: { type: String, default: 'hour' },
    minKey: { type: String, default: 'min' },
  },
  computed: {
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
    innerValue() {
      if (!this.value) {
        return undefined
      }
      const hour = Math.floor(this.value / 60)
      const min = this.value % 60
      const value = Moment(`${hour}:${min}`, 'k:m').format('hh:mm A')
      return value
    },
  },
  methods: {
    handleChange(value) {
      if (!value) {
        this.$emit('change', undefined)
        this.$emit('selected', {
          text: '',
        })
        return
      }
      const time = Moment(value, 'hh:mm A')
      const hour = parseInt(time.format('HH'), 10)
      const min = parseInt(time.format('mm'), 10)
      const change = hour * 60 + min
      this.$emit('change', change)
      this.$emit('selected', {
        text: Moment(`${hour}:${min}`, 'k:m').format('hh:mm A'),
      })
    },
  },
}
</script>
