<script>
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'ContractStatusPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return [
        {
          id: 'not_started',
          key: 'not_started',
          text: this.$tc('not_started'),
        },
        { id: 'active', key: 'active', text: this.$tc('active') },
        { id: 'expired', key: 'expired', text: this.$tc('expired') },
        { id: 'cancelled', key: 'cancelled', text: this.$tc('cancelled') },
      ]
    },
  },
}
</script>
