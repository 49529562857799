<script>
import { getChangeModelsApi } from '@modules/change-model/change-model-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoTransitionModelPicker',
  extends: _baseDropdownPicker,
  props: {
    moduleName: { type: String, default: undefined, required: true },
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    serviceCatalogId: { type: [Number, String], default: undefined },
    overlayClassName: {
      type: String,
      default: 'picker-overlay wide-picker-overlay',
    },
  },
  data() {
    this.unassignedValue = 0
    return {
      loadedOptions: [],
    }
  },
  computed: {
    options() {
      return this.loadedOptions
    },
  },
  created() {
    getChangeModelsApi({
      moduleName: this.moduleName,
      transform: false,
      ...(this.serviceCatalogId ? { parentId: +this.serviceCatalogId } : {}),
    }).then(
      ({ items }) =>
        (this.loadedOptions = items.map((t) => ({ ...t, key: t.id })))
    )
  },
}
</script>
