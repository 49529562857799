<script>
import { getSupportPortalsApi } from '@modules/it-infrastructure/api/support-portal-crud-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'SupportPortalPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    value: { type: [Array, String, Number], default: undefined },
  },
  data() {
    this.unassignedValue = 0
    return {
      list: [],
      selectedEventName: 'configSelected',
    }
  },
  computed: {
    options() {
      return this.list
    },
  },
  created() {
    getSupportPortalsApi().then(({ items }) => {
      const options = (this.list = items.map((t) => ({
        archived: !t.enabled,
        key: t.supportPortalUrl.toLowerCase(),
        text: t.name,
      })))
      if (this.value) {
        const value = Array.isArray(this.value) ? this.value : [this.value]
        const archivedValue = value.filter(
          (v) => options.map((o) => o.key).indexOf(v) === -1
        )
        if (archivedValue.length) {
          const v = value.filter(
            (v) => options.map((o) => o.key).indexOf(v) >= 0
          )
          this.$emit('change', v)
        }
      }
      return options
    })
  },
}
</script>
