<template>
  <CustomIcon :name="iconName" v-bind="$attrs" />
</template>

<script>
import { findValueObject } from '@data/recursive'
import { AssetTypeComputed } from '@state/modules/asset-type'
import { CITypeComputed } from '@state/modules/ci-type'
import CustomIcon from './custom-icon'
export default {
  name: 'AssetTypeIcon',
  components: { CustomIcon },
  props: {
    assetTypeId: { type: [Number, String], default: undefined },
    ciTypeId: { type: [Number, String], default: undefined },
  },
  computed: {
    ...AssetTypeComputed,
    ...CITypeComputed,
    item() {
      if (this.ciTypeId) {
        return findValueObject(this.ciTypes, this.ciTypeId) || {}
      }
      if (this.assetTypeId) {
        return findValueObject(this.assetTypes, this.assetTypeId) || {}
      }
      return {}
    },
    iconName() {
      const item = this.item
      if (item.systemName) {
        return `${
          (item.systemName || '').split(' ').join('').toLowerCase() || ''
        }Icon`
      }
      if (item.iconSrc) {
        return item.iconSrc
      }
      return 'otherIcon'
    },
  },
}
</script>
