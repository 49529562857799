<template>
  <div>
    <FlotoForm layout="vertical" class="flex items-center">
      <div class="flex w-full">
        <MInputGroup compact class="input-group-container">
          <div class="flex-1">
            <MInputNumber
              id="day-time-picker-input"
              v-model="textValue"
              :disabled="disabled"
              :precision="0"
              :min="0"
              :max="maxValue"
              :placeholder="$tc(currentType, 3)"
            />
          </div>
          <MSelect
            v-if="durationOptions.length > 1 || allowedUnits.length"
            v-model="currentType"
            :placeholder="$t('select')"
            :options="durationOptions"
            as-input
            :disabled="durationOptions.length === 1"
            :dropdown-match-select-width="false"
            :allow-clear="false"
            class="duration-typess"
            :class="dayTimeTriggerStyle"
            input-classes="rounded-tl-none rounded-bl-none"
            @change="handleTimeUnitChange"
          >
          </MSelect>
        </MInputGroup>
      </div>
      <template v-slot:submit>
        <span />
      </template>
    </FlotoForm>
  </div>
</template>

<script>
import Moment from 'moment'

export default {
  name: 'FlotoDaytimePicker',
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Number, default: 0 },
    dayTimeTriggerStyle: {
      type: Object,
      default() {
        return {}
      },
    },
    allowedUnits: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      textValue: undefined,
      currentType: (this.allowedUnits || []).length
        ? this.allowedUnits[0]
        : 'hours',
      timeUnit: 0,
    }
  },
  computed: {
    maxValue() {
      if (this.currentType === 'hours') {
        return 720
      }
      if (this.currentType === 'days') {
        return 30
      }
      if (this.currentType === 'minutes') {
        return 43200
      }
      return undefined
    },
    durationOptions() {
      let allOptions = [
        { text: this.$tc('hours', 3), value: 'hours', key: 'hours' },
        { text: this.$tc('days', 3), value: 'days', key: 'days' },
        { text: this.$tc('minutes', 3), value: 'minutes', key: 'minutes' },
      ]
      if (this.allowedUnits.length) {
        allOptions = allOptions.filter(
          ({ value }) => this.allowedUnits.indexOf(value) >= 0
        )
      }
      return allOptions
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.textValue = this.convertValueForText()
      },
    },
    textValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => this.update())
      }
    },
  },
  updated() {
    this.timeUnit = `${this.textValue} ${this.$tc(this.currentType, 3)}`
    this.$emit('descriptionValue', this.timeUnit)
  },
  methods: {
    setTimeUnit(unit) {
      this.currentType = unit
      this.$nextTick(() => this.update())
    },
    handleTimeUnitChange($event) {
      this.$emit('timeUnit', $event)
      this.$nextTick(() => this.update())
    },
    formatedValue(value) {
      return value && value >= 0 ? +value : 0
    },
    update() {
      const v = parseInt(this.textValue)
      if (v === 0 || isNaN(v)) {
        this.$emit('change', 0)
      } else {
        const m = Moment.duration(v, this.currentType)
        this.$emit('change', m.asMilliseconds())
      }
    },
    convertValueForText() {
      const formatedValue = this.formatedValue(this.value)
      if (formatedValue) {
        const m = Moment.duration(formatedValue, 'milliseconds')
        if (m.asMinutes() < 60) {
          this.currentType = 'minutes'
          return m.asMinutes()
        }
        if (m.asDays() >= 1) {
          this.currentType = 'days'
          if (m.asHours() % 24 <= 0) {
            return m.asDays()
          }
          this.currentType = 'hours'
          return m.asHours()
        }
        this.currentType = 'hours'
        return m.asHours()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.input-group-container {
  top: 0 !important;
  display: flex !important;
  .@{ant-prefix}-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<style lang="less">
.input-group-container {
  .duration-type {
    .@{ant-prefix}-select-selection {
      color: var(--page-text-color) !important;
      background-color: var(--page-background-color) !important;
      border-color: var(--border-color);
    }
  }
}
.@{ant-prefix}-select-dropdown-menu-item {
  background-color: var(--dropdown-background);
}
.@{ant-prefix}-select-dropdown-menu-item:hover {
  color: var(--primary);
  background-color: var(--dropdown-hover-bg) !important;
}
</style>
