import api from '@api'
import {
  transformEmailNotification,
  transformEmailNotificationForServer,
} from '@data/email-notification'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
const __t = getRootTranslator()
const __tc = getRootPluaralTranslator()

export function getNotificationApi(moduleName, id, pageSize, offset) {
  return api
    .get(`${moduleName}/${id}/manualNotifications/`, {
      params: {
        offset: offset || 0,
        size: pageSize || 1000,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformEmailNotification),
        total: data.totalCount,
      }
    })
}

export function createNotificationApi(moduleName, id, notification) {
  return api
    .post(
      `${moduleName}/${id}/manualNotifications`,
      transformEmailNotificationForServer(notification),
      {
        message: __t('created_successfully', {
          resource: __tc('email_notification'),
        }),
      }
    )
    .then((data) => transformEmailNotification(data))
}
