<script>
import { getGlCodesApi } from '@modules/purchase-management/api/gl-code-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'GlCodePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      loadedGlCode: [],
      selectedEventName: 'glCodeSelected',
    }
  },
  computed: {
    options() {
      return this.loadedGlCode
    },
  },
  created() {
    getGlCodesApi({ transform: false }).then(
      ({ items }) =>
        (this.loadedGlCode = items.map((t) => ({
          ...t,
          key: t.id,
          name: t.glCode,
        })))
    )
  },
}
</script>
