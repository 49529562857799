<script>
import { getDeploymentPoliciesApi } from '@modules/patch-management/api/deployment-policy-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'DeploymentPolicyPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      loadedOptions: [],
    }
  },
  computed: {
    options() {
      return this.loadedOptions
    },
  },
  created() {
    getDeploymentPoliciesApi().then(
      ({ items }) =>
        (this.loadedOptions = items.map((i) => ({ ...i, key: i.id })))
    )
  },
}
</script>
