<script>
import { getRolesApi } from '@modules/roles/roles-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoRolePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.selectedEventName = 'roleSelected'
    return {
      loadedOptions: [],
    }
  },
  computed: {
    options() {
      return this.loadedOptions
    },
  },
  created() {
    getRolesApi(null, 1000, 0, { fullobject: false }).then(({ items }) => {
      this.$emit(
        'super-admin-role',
        (items || []).filter((i) => i.roleType === 'super_admin')
      )
      this.$emit(
        'dashboard-viewer-role',
        (items || []).filter((i) => i.name === 'Dashboard Viewer')
      )
      this.$emit(
        'report-viewer-role',
        (items || []).filter((i) => i.name === 'Report Viewer')
      )
      return (this.loadedOptions = items.map((i) => ({ ...i, key: i.id })))
    })
  },
}
</script>
