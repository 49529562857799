<template>
  <MComment>
    <template v-slot:avatar>
      <MTooltip>
        <template v-slot:trigger>
          <div>
            <FlotoUserAvatar
              size="large"
              icon="user"
              :user-id="notification.owner"
            />
          </div>
        </template>
        {{ ownerUser.name }}
      </MTooltip>
    </template>
    <template v-slot:content>
      <div class="bordered rounded p-2">
        <MRow>
          <MCol :size="12" class="mb-2">
            <h5>{{ notification.subject }}</h5>
          </MCol>
          <MCol v-if="notification.emails.length" :size="4">
            <div class="text-neutral">
              <small>
                {{ $tc('email_plural', 2) }}
              </small>
            </div>
            <div>
              <MTag
                v-for="email in notification.emails"
                :key="email"
                :closable="false"
                class="cursor-default"
                variant="neutral-lighter"
              >
                {{ email }}
              </MTag>
            </div>
          </MCol>
          <MCol v-if="notification.technicianGroups.length" :size="4">
            <div class="text-neutral">
              <small>
                {{ $t('technician_group') }}
              </small>
            </div>
            <div>
              <template v-for="technician in notification.technicianGroups">
                <MTag
                  v-if="requiredGroups[technician]"
                  :key="technician"
                  :closable="false"
                  class="cursor-default"
                  variant="neutral-lighter"
                >
                  {{ requiredGroups[technician].name }}
                </MTag>
              </template>
            </div>
          </MCol>
          <MCol v-if="notification.requesterGroups.length" :size="4">
            <div class="text-neutral">
              <small>
                {{ $t('requester_group') }}
              </small>
            </div>
            <div>
              <template v-for="requester in notification.requesterGroups">
                <MTag
                  v-if="requiredGroups[requester]"
                  :key="requester"
                  :closable="false"
                  class="cursor-default"
                  variant="neutral-lighter"
                >
                  {{ requiredGroups[requester].name }}
                </MTag>
              </template>
            </div>
          </MCol>
        </MRow>
        <MRow class="mt-2">
          <MCol>
            <div class="text-neutral">
              <small>
                {{ $t('content') }}
              </small>
            </div>
            <div>
              <FlotoReadMore :height="8 * 20">
                <FlotoRichEditor
                  :value="notification.content"
                  :disabled="true"
                />
              </FlotoReadMore>
            </div>
          </MCol>
        </MRow>
        <MRow :gutter="0">
          <MCol :size="12">
            <FlotoAttachment :value="notification.attachments" disabled />
          </MCol>
          <MCol :size="12" class="mt-2">
            <MRow>
              <MCol auto-size>
                <div class="text-neutral">
                  <small>
                    {{ $tc('email') }} {{ $t('sent') }} {{ $t('by') }}
                    <FlotoUserDrawer :user="ownerUser" disabled />
                  </small>
                </div>
              </MCol>
              <MCol auto-size :class="{ 'mr-auto': isRtl, 'ml-auto': !isRtl }">
                <div class="text-neutral">
                  <small>
                    {{ notification.createdAt | datetime }} ({{
                      notification.createdAt | timeago
                    }})
                  </small>
                </div>
              </MCol>
            </MRow>
          </MCol>
        </MRow>
      </div>
    </template>
  </MComment>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { UserGroupsComputed } from '@state/modules/user-group'
import { TechnicianComputed } from '@state/modules/technician'

export default {
  name: 'EmailNotification',
  props: {
    notification: { type: Object, required: true },
  },
  data() {
    return {
      requiredGroups: {},
    }
  },
  computed: {
    ...authComputed,
    ...UserGroupsComputed,
    ...TechnicianComputed,
    ownerUser() {
      return this.technician(this.notification.owner) || {}
    },
  },
  created() {
    const groupsMap = {}
    this.notification.technicianGroups.forEach((g) => {
      groupsMap[g] = this.technicianGetGroupById(g)
    })
    this.notification.requesterGroups.forEach((g) => {
      groupsMap[g] = this.requesterGetGroupById(g)
    })
    this.requiredGroups = groupsMap
  },
}
</script>
