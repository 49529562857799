<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchRemoteOffice"
    :disabled="disabled"
    :as-input="asInput"
    :placeholder="placeholder"
    :immediate-search="immediateSearch"
    :transform-options-fn="transformVendor"
    :text-only="textOnly"
    v-bind="$attrs"
    @change="$emit('change', $event || unassignedValue)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"> </slot>
    </template>
    <template v-slot:no-data>
      <h5
        class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
      >
        <MIcon name="search" class="mr-2" />
        {{ $tc('type_to_search') }}
      </h5>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getRemoteOfficesApi } from '@modules/patch-management/api/remote-office-api'

export default {
  name: 'RemoteOfficePicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, Array, String], default: undefined },
    disabled: { type: Boolean, default: false },
    immediateSearch: { type: Boolean, default: false },
    placeholder: { type: String, default: undefined },
    textOnly: { type: Boolean, default: false },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    return {
      isDirty: false,
    }
  },
  methods: {
    transformVendor({ items }) {
      return items.map((m) => ({ ...m, text: m.name, key: m.id }))
    },
    searchRemoteOffice(name, selectedItems) {
      let selectedItemNames = selectedItems.map((i) => i.text)
      if (this.value && !this.isDirty) {
        const ids = Array.isArray(this.value) ? this.value : [this.value] || []
        return getRemoteOfficesApi({ ids }, ids.length || 10, 0, {
          archived: true,
        }).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return getRemoteOfficesApi(
        { name: name, selectedName: selectedItemNames },
        this.immediateSearch ? undefined : 10,
        0
      ).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>
