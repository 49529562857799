<script>
import Range from 'lodash/range'
import DeepClone from 'lodash/cloneDeep'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const _t = getRootPluaralTranslator()
export const taskDependencyStageOptions = () => {
  const stages = []
  Range(1, 16).forEach((r) => {
    stages.push({ text: `${_t('stage')} ${r}`, key: r })
  })
  return stages
}

export default {
  name: 'TaskDependencyStagePicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(taskDependencyStageOptions())
    },
  },
}
</script>
