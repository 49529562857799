<script>
import { TaskComputed } from '@state/modules/task'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoTaskTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    excludedTypes: {
      type: Array,
      default() {
        return []
      },
    },
    mandatory: { type: Boolean, default: false },
  },
  computed: {
    ...TaskComputed,
    currentOptions() {
      let currentList = this.taskTypeOptions || []
      if (this.excludedTypes.length) {
        currentList = currentList.filter(
          (t) =>
            this.excludedTypes.indexOf((t.systemName || '').toLowerCase()) ===
            -1
        )
        return currentList
      } else {
        return currentList
      }
    },
    options() {
      return this.currentOptions
    },
  },
  created() {
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      const defaultValueSystemName =
        this.$attrs['defalut-task-type-system-name'] || 'Implementation'
      if (
        !this.multiple &&
        !this.value &&
        this.mandatory &&
        defaultValueSystemName
      ) {
        let defaultValue = this.options.find(
          (v) =>
            (v.systemName || '').toLowerCase() ===
            defaultValueSystemName.toLowerCase()
        )
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
          this.$emit('set-default-value', defaultValue.key)
        }
      }
    },
  },
}
</script>
