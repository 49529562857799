<script>
import UniqBy from 'lodash/uniqBy'
import { searchSsoConfigApi } from '@modules/it-infrastructure/api/sso-config-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'SsoConfigPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      archivedOptions: [],
      list: [],
      selectedEventName: 'configSelected',
    }
  },
  computed: {
    options() {
      // return this.list
      const options = this.list
      const filteredOptions = options.filter((c) => !c.archived)
      const value = Array.isArray(this.value) ? this.value : [this.value]
      const selectedOptions = value.length
        ? options
            .filter((o) => value.indexOf(o.id) >= 0)
            .map((o) => ({ ...o, disabled: true }))
        : []
      let avialableOptions = filteredOptions
      if (selectedOptions.length) {
        const availableIds = filteredOptions.map((f) => f.key)
        avialableOptions = avialableOptions.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.key) === -1)
        )
      }
      return UniqBy(avialableOptions.concat(this.archivedOptions), 'key')
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newValue) {
        if (this.value && newValue.length) {
          const v = Array.isArray(this.value) ? this.value : [this.value]
          const options = newValue
          const avialableOptions = this.$attrs['additional-options']
            ? (this.$attrs['additional-options'] || []).concat(options)
            : options
          const allAvailableServiceIds = avialableOptions.map((u) => u.key)
          const missingItems = v.filter(
            (id) => allAvailableServiceIds.indexOf(id) === -1
          )
          if (missingItems.length) {
            if (this.validateArchivedValue && !this.multiple) {
              this.$emit('change', this.unassignedValue)
            } else {
              this.fetchMissingItems(missingItems)
            }
          }
        }
      },
    },
  },
  created() {
    searchSsoConfigApi({}).then(
      ({ items }) =>
        (this.list = items.map((t) => ({ ...t, key: t.id, text: t.name })))
    )
  },
  methods: {
    fetchMissingItems(missingItems) {
      searchSsoConfigApi(
        {
          ids: missingItems,
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            ...c,
            key: c.id,
            text: c.archived ? `${c.name} (${this.$tc('archived')})` : c.name,
            disabled: true,
            archived: true,
          }))
        )
      })
    },
  },
}
</script>
