<template>
  <div>
    <slot>
      <a
        v-if="clickable"
        target="_blank"
        :href="
          valueItem && valueItem.name
            ? $router.resolve(
                moduleName === $constants.CMDB
                  ? $modules.getModuleRoute('cmdb', 'view', {
                      params: {
                        id: valueItem.id,
                        ciType: moduleName,
                      },
                    })
                  : $modules.getModuleRoute('asset', 'view', {
                      params: {
                        id: valueItem.id,
                        assetType: moduleName,
                      },
                    })
              ).href
            : undefined
        "
      >
        <span v-if="valueItem && value && valueItem.name">
          {{
            showOnlyName
              ? `${valueItem.name}`
              : `${valueItem.name} ${valueItem.displayName}`
          }}
        </span>
        <span v-else class="text-neutral-dark">
          {{ placeholder }}
        </span>
      </a>
      <FlotoDropdownPicker
        v-else
        :disabled="disabled"
        :options="[]"
        :show-no-data="false"
      >
        <template v-slot:trigger="triggerSlotData">
          <DropdownTrigger
            :toggle="triggerSlotData.toggle"
            :disabled="disabled"
            :title="
              valueItem ? `${valueItem.name} ${valueItem.displayName}` : ''
            "
            :selected-item="
              valueItem
                ? {
                    text: `${valueItem.name} ${valueItem.displayName}`,
                    key: valueItem.id,
                  }
                : {}
            "
            :allow-clear="allowClear"
            :as-input="asInput"
            :is-open="false"
            :placeholder="placeholder"
            @click="handleTriggerClick"
            @reset="handleResert"
          />
        </template>
      </FlotoDropdownPicker>
    </slot>

    <FlotoDrawer :open="showDrawer" width="55%" @hide="hideDrawer">
      <template v-slot:title>
        {{ placeholder }}
      </template>
      <AssetSelectionList
        hide-search-selector
        selectable
        :fetch-fn="fetchAssetsFn"
        :module-name="moduleName"
        searchable
        :exclude-items-by-id="excludeItemsById"
        :max-allowed-selection="1"
        @selection-change="setSelectedItems"
      />
      <template v-slot:actions="{ hide }">
        <MButton
          id="add-btn"
          :disabled="selectedItems.length === 0"
          outline
          class="mr-2"
          :loading="processing"
          @click="handleSubmitSelection"
        >
          {{ $t('add') }}
        </MButton>
        <MButton id="cancel-btn" variant="default" @click="hide">
          {{ $t('cancel') }}
        </MButton>
      </template>
    </FlotoDrawer>
  </div>
</template>

<script>
import {
  getApi,
  searchSoftwareAssetsWithoutOSApi,
  searchAssetsApi,
} from '@modules/asset/asset-api'
import DropdownTrigger from '@components/dropdown-trigger'
import AssetSelectionList from '@components/item-selection-list/asset-list'
export default {
  name: 'AssetPickerDrawer',
  components: { AssetSelectionList, DropdownTrigger },
  model: {
    event: 'change',
  },
  props: {
    value: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
    clickable: { type: Boolean, default: false },
    fetchFn: { type: Function, default: undefined },
    moduleName: { type: String, default: undefined },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    placeholder: {
      type: String,
      default() {
        return `${this.$tc('select')} ${this.$tc(this.moduleName)}`
      },
    },
    showOnlyName: { type: Boolean, default: false },
  },
  data() {
    return {
      showDrawer: false,
      processing: false,
      selectedItems: [],
      valueItem: null,
    }
  },
  computed: {
    excludeItemsById() {
      if (this.value) {
        return [this.value]
      }
      return []
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          getApi(
            this.moduleName,
            newValue,
            {
              archived: true,
            },
            false
          )
            .then((data) => {
              this.valueItem = {
                ...data,
                ...(this.moduleName === this.$constants.CMDB
                  ? {
                      displayName: data.ciDisplayName,
                      name: data.ciName,
                    }
                  : {}),
              }
            })
            .catch(() => {
              this.handleResert()
            })
        }
      },
    },
  },
  methods: {
    fetchAssetsFn(moduleName, criterias, limit, offset) {
      if (this.fetchFn) {
        return this.fetchFn(moduleName, criterias, limit, offset)
      }
      if (
        [this.$constants.ASSET_HARDWARE, this.$constants.CMDB].indexOf(
          this.moduleName
        ) >= 0
      ) {
        return searchAssetsApi(moduleName, criterias, limit, offset)
      }
      return searchSoftwareAssetsWithoutOSApi(criterias, limit, offset)
    },
    handleSubmitSelection() {
      if (this.processing) {
        return
      }
      this.processing = true
      this.$emit('change', this.selectedItems.map((i) => i.id)[0])
      this.processing = false
      this.hideDrawer()
    },
    handleResert() {
      this.$emit('change', 0)
      this.valueItem = null
    },
    hideDrawer() {
      this.selectedItems = []
      this.showDrawer = false
    },
    setSelectedItems(items) {
      this.selectedItems = items
    },
    handleTriggerClick() {
      if (this.disabled) {
        return
      }
      setTimeout(() => {
        this.showDrawer = true
      }, 250)
    },
  },
}
</script>
