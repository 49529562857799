<script>
import IsEqual from 'lodash/isEqual'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getPrintTemplatesApi } from '@modules/print-template/print-template-api'

export default {
  name: 'PrintTemplatePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    mandatory: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    onlyEnabledTemplatesAllowed: { type: Boolean, default: false },
    overlayClassName: {
      type: String,
      default: 'picker-overlay wide-picker-overlay',
    },
    serviceCatalogId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    this.unassignedValue = null
    return {
      templates: [],
    }
  },
  computed: {
    options() {
      return this.templates
    },
  },
  watch: {
    options: {
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
  },
  created() {
    const filter = {
      enabled: true,
    }
    getPrintTemplatesApi(
      this.moduleName,
      filter,
      undefined,
      undefined,
      undefined,
      this.serviceCatalogId
        ? {
            serviceCatalogId: this.serviceCatalogId,
          }
        : {}
    ).then(({ items }) => {
      let options = []
      options = items.map((t) => ({
        ...t,
        text: t.text || t.name,
        key: t.id,
      }))
      this.templates = this.onlyEnabledTemplatesAllowed
        ? options.filter((f) => f.enabled)
        : options
      return this.templates
    })
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      if (!this.value && this.mandatory) {
        let defaultValue = this.options.find((i) => i.primary)
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
        }
      }
    },
  },
}
</script>
