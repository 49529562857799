<template>
  <MTimePicker
    v-bind="$attrs"
    :use12-hours="use12Hours"
    :format="timeFormat"
    v-on="$listeners"
  />
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@/src/state/modules/organization'
export default {
  name: 'FlotoTimePicker',
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    timeFormat() {
      let tFormat
      if (this.organization.timeFormat) {
        tFormat = this.organization.timeFormat
      }
      if (this.user.timeFormat) {
        tFormat = this.user.timeFormat
      }
      return tFormat
    },
    use12Hours() {
      return (this.timeFormat || '').indexOf('A') >= 0
    },
  },
}
</script>
