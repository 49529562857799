<script>
import DeepClone from 'lodash/cloneDeep'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getRootPluaralTranslator } from '@utils/get-module-translator'

const __tc = getRootPluaralTranslator()
export const ProjectSourceOptions = () => [
  { id: 'manual', key: 'manual', text: __tc('manual') },
  { id: 'csv', key: 'csv', text: __tc('csv') },
  { id: 'excel', key: 'excel', text: __tc('excel') },
  { id: 'mpp', key: 'mpp', text: __tc('mpp') },
  { id: 'clone', key: 'clone', text: __tc('clone') },
]

export default {
  name: 'ProjectSourcePicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(ProjectSourceOptions())
    },
  },
}
</script>
