<script>
import { getBusinessHoursApi } from '@modules/business-hours/business-hours-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoBusinessHourPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      loadedBusinessHours: [],
    }
  },
  computed: {
    options() {
      return this.loadedBusinessHours
    },
  },
  created() {
    getBusinessHoursApi().then(
      ({ items }) =>
        (this.loadedBusinessHours = items
          .filter(({ enabled }) => enabled)
          .map((t) => ({
            label: t.name,
            key: t.id,
          })))
    )
  },
}
</script>
