<template>
  <TreePicker
    :options="availableOptions"
    :value="value"
    searchable
    :placeholder="placeholder"
    :unassigned-value="unassignedValue"
    :multiple="multiple"
    v-bind="$attrs"
    :as-input="asInput"
    :only-leaf-node-selectable="isOnlyLeafNodeSelectable"
    @blur="$emit('blur')"
    @change="$emit('change', $event)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"></slot>
    </template>
  </TreePicker>
</template>

<script>
import SortBy from 'lodash/sortBy'
import { flattenRecursive, buildHierarchy } from '@data/recursive'
import { LocationComputed, searchLocationsApi } from '@state/modules/location'
import { buildRelationalQualificationStructure } from '@data/qualification'
import TreePicker from '@components/tree-picker/tree-picker'
// import _baseTreePicker from '../_base-tree-select.vue'

export default {
  name: 'FlotoLocationPicker',
  // extends: _baseTreePicker,
  components: { TreePicker },
  model: { event: 'change' },

  props: {
    moduleName: { type: String, default: undefined },
    validateArchivedValue: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$tc('select')
      },
    },
    unassignedValue: { type: [String, Number], default: 0 },
    value: { type: [Number, Array, String], default: undefined },
    // eslint-disable-next-line
    onlyLeafNodeSelectable: { type: Boolean, default: true },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    ignoreHeirarchyConfig: { type: Boolean, default: false },
  },
  data() {
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...LocationComputed,
    options() {
      const options = this.locationOptions
      const flattenedOptions = flattenRecursive(options)
      const flatOptions = SortBy(
        flattenedOptions.filter((c) => !c.archived),
        'order'
      )
      const value = Array.isArray(this.value) ? this.value : [this.value]
      const selectedOptions = value.length
        ? flattenedOptions
            .filter((o) => value.indexOf(o.id) >= 0)
            .map((o) => ({ ...o, disabled: true }))
        : []
      let hierarchy = buildHierarchy(flatOptions)
      if (selectedOptions.length) {
        const availableIds = flatOptions.map((f) => f.id)
        hierarchy = hierarchy.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.id) === -1)
        )
      }
      return hierarchy.concat(this.archivedOptions)
    },
    availableOptions() {
      if (this.$attrs['additional-options']) {
        return (this.$attrs['additional-options'] || []).concat(this.options)
      }
      return this.options
    },
    transformedModuleName() {
      if (this.moduleName === this.$constants.SERVICE_CATALOG) {
        return this.$constants.REQUEST
      }
      return this.moduleName
    },
    isOnlyLeafNodeSelectable() {
      if (this.onlyLeafNodeSelectable) {
        if (this.ignoreHeirarchyConfig) {
          return this.onlyLeafNodeSelectable
        }
        return (
          this[`${this.transformedModuleName}OnlyLeafNodeSelectable`] || false
        )
      }
      return false
    },
  },
  created() {
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const flattenedOptions = this.$attrs['additional-options']
        ? (this.$attrs['additional-options'] || []).concat(
            flattenRecursive(options)
          )
        : flattenRecursive(options)
      const allAvailableUserIds = flattenedOptions.map((u) => u.id)
      const missingLocations = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1 && !isNaN(userId)
      )
      if (missingLocations.length) {
        if (this.validateArchivedValue && !this.multiple) {
          this.$emit('change', this.unassignedValue)
        } else {
          this.fetchMissingLocations(missingLocations)
        }
      }
    }
  },
  methods: {
    fetchMissingLocations(locationIds) {
      searchLocationsApi(
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              locationIds,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            ...c,
            disabled: c.archived || c.disabled,
          }))
        )
      })
    },
  },
}
</script>
