<template>
  <div class="flex w-full">
    <MInputGroup compact class="input-group-container">
      <div class="flex-1">
        <MInputNumber
          :value="convertedValue"
          :min="min"
          :max="max ? max : 2100000000"
          :precision="2"
          v-bind="$attrs"
          @update="handleChange"
        />
      </div>
      <div
        v-if="!hideUnitSelection"
        class="px-2 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none"
        style="display: inline-flex"
      >
        {{ $tc(unit) }}
      </div>
    </MInputGroup>
  </div>
</template>

<script>
export default {
  name: 'BytesPicker',
  model: { event: 'change' },
  props: {
    value: { type: Number, default: 0 },
    hideUnitSelection: { type: Boolean, default: false },
    unit: {
      type: String,
      default() {
        return 'gb'
      },
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    valueInBytes() {
      if (this.unit === 'gb') {
        return 1024 * 1024 * 1024
      }
      if (this.unit === 'mb') {
        return 1024 * 1024
      }
      return 1
    },
    convertedValue() {
      const v = parseFloat(this.value)
      if (isNaN(v)) {
        return
      }
      return v / this.valueInBytes
    },
  },
  methods: {
    handleChange(value) {
      const v = parseFloat(value)
      if (isNaN(v)) {
        return
      }
      this.$emit('change', v * this.valueInBytes)
    },
  },
}
</script>

<style lang="less" scoped>
.input-group-container {
  top: 3px !important;
  display: flex !important;
  .@{ant-prefix}-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<style lang="less">
.input-group-container {
  .duration-type {
    .@{ant-prefix}-select-selection {
      @apply bg-neutral-lightest text-neutral;
    }
  }
}
</style>
