<script>
import DeepClone from 'lodash/cloneDeep'
import { assetMovementStatusOptions } from '@modules/asset-management/helpers/option-map'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'AssetMovementStatusPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(assetMovementStatusOptions())
    },
  },
}
</script>
