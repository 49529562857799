var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FlotoCrudContainer',{attrs:{"default-item":_vm.defaultNotification,"fetch-fn":_vm.getNotifications,"scrollable":false,"create-fn":_vm.addNotification,"show-no-data":false},scopedSlots:_vm._u([{key:"add-controls",fn:function(ref){
var create = ref.create;
return [(!_vm.disabled)?_c(_vm.stickySearch ? 'MAffix' : 'div',{tag:"Component",attrs:{"offset-top":_vm.offsetTop},on:{"change":function($event){_vm.searchAffixed = $event}}},[_c('div',{staticClass:"sticky-tab-header",class:{ 'pt-2': _vm.searchAffixed }},[_c('MRow',[_c('MCol',{staticClass:"m-align-button"},[_c('MButton',{attrs:{"id":"send-email-btn","variant":"neutral"},on:{"click":create}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" "+_vm._s(_vm.$tc('email_plural'))+" ")])],1)],1),_c('MDivider')],1)]):_vm._e()]}},{key:"form-header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('send'))+" "+_vm._s(_vm.$tc('email_plural'))+" ")]},proxy:true},{key:"form-items",fn:function(ref){
var notification = ref.item;
return [_c('EmailNotificationForm',{attrs:{"notification":notification,"module-name":_vm.moduleName}})]}},{key:"form-actions",fn:function(ref){
var submit = ref.submit;
var cancel = ref.cancel;
var processing = ref.processing;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"send-btn","loading":processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('EmailNotification',{key:item.id,attrs:{"notification":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }