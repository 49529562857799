export const dateTimeFormatOptions = () => [
  {
    text: 'ddd, MMM DD, YYYY hh:mm A',
    key: 'ddd, MMM DD, YYYY hh:mm A',
    value: 'ddd, MMM DD, YYYY hh:mm A',
  },
  {
    text: 'ddd, MMM DD, YYYY HH:mm',
    key: 'ddd, MMM DD, YYYY HH:mm',
    value: 'ddd, MMM DD, YYYY HH:mm',
  },
  {
    text: 'dddd, DD MMMM YYYY hh:mm A',
    key: 'dddd, DD MMMM YYYY hh:mm A',
    value: 'dddd, DD MMMM YYYY hh:mm A',
  },
  {
    text: 'dddd, DD MMMM YYYY HH:mm',
    key: 'dddd, DD MMMM YYYY HH:mm',
    value: 'dddd, DD MMMM YYYY HH:mm',
  },
  {
    text: 'DD MMMM YYYY hh:mm A',
    key: 'DD MMMM YYYY hh:mm A',
    value: 'DD MMMM YYYY hh:mm A',
  },
  {
    text: 'DD MMMM YYYY HH:mm',
    key: 'DD MMMM YYYY HH:mm',
    value: 'DD MMMM YYYY HH:mm',
  },
  {
    text: 'MMMM DD, YYYY hh:mm A',
    key: 'MMMM DD, YYYY hh:mm A',
    value: 'MMMM DD, YYYY hh:mm A',
  },
  {
    text: 'MMMM DD, YYYY HH:mm',
    key: 'MMMM DD, YYYY HH:mm',
    value: 'MMMM DD, YYYY HH:mm',
  },
]

export const dateFormatOptions = () => [
  {
    text: 'DD/MM/YYYY',
    key: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  },
  {
    text: 'MM/DD/YYYY',
    key: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
  {
    text: 'YYYY/MM/DD',
    key: 'YYYY/MM/DD',
    value: 'YYYY/MM/DD',
  },
  {
    text: 'DD-MM-YYYY',
    key: 'DD-MM-YYYY',
    value: 'DD-MM-YYYY',
  },
  {
    text: 'MM-DD-YYYY',
    key: 'MM-DD-YYYY',
    value: 'MM-DD-YYYY',
  },
  {
    text: 'YYYY-MM-DD',
    key: 'YYYY-MM-DD',
    value: 'YYYY-MM-DD',
  },
]

export const timeFormatOptions = () => [
  {
    text: '12 Hours',
    key: 'hh:mm A',
    value: 'hh:mm A',
  },
  {
    text: '24 Hours',
    key: 'HH:mm',
    value: 'HH:mm',
  },
]
