<script>
import DeepClone from 'lodash/cloneDeep'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'TechnicianFromPicker',
  extends: _baseDropdownPicker,
  props: {
    moduleName: {
      type: String,
      default() {
        return this.$constants.CHANGE
      },
    },
  },
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    technicianFromOptions() {
      let options = [
        {
          key: 'changeImplementor',
          text: `${this.$tc('change')} ${this.$tc('implementer')}`,
        },
        {
          key: 'changeManager',
          text: `${this.$tc('change')} ${this.$tc('manager')}`,
        },
        {
          key: 'changeReviewer',
          text: `${this.$tc('change')} ${this.$tc('reviewer')}`,
        },
      ]
      if (this.moduleName === this.$constants.RELEASE) {
        options = [
          {
            key: 'releaseEngineer',
            text: `${this.$tc('release')} ${this.$tc('engineer')}`,
          },
          {
            key: 'releaseManager',
            text: `${this.$tc('release')} ${this.$tc('manager')}`,
          },
          {
            key: 'qaManager',
            text: `${this.$tc('release')} ${this.$tc('qa')} ${this.$tc(
              'manager'
            )}`,
          },
          {
            key: 'releaseReviewer',
            text: `${this.$tc('release')} ${this.$tc('reviewer')}`,
          },
        ]
      }
      return options
    },
    options() {
      return DeepClone(this.technicianFromOptions)
    },
  },
}
</script>
