<template>
  <MTag
    v-if="!asInput && !textOnly"
    :closable="false"
    :class="{ disabled: disabled, 'text-ellipsis': true }"
    :style="{ ...currentStyle, maxWidth: '150px', display: 'inline-flex' }"
    :variant="
      selectedItem && selectedItem.color ? undefined : 'neutral-lighter'
    "
    :title="
      selectedItem
        ? selectedItem.text || selectedItem.name || selectedItem.label
        : placeholder
    "
  >
    <slot name="prefix-text" :item="selectedItem" />
    <span class="text-ellipsis">
      {{
        selectedItem
          ? selectedItem.text || selectedItem.name || selectedItem.label
          : placeholder
      }}
    </span>
    <slot name="suffix-text" />
  </MTag>
  <span
    v-else-if="textOnly"
    class="truncate-text"
    :title="
      selectedItem
        ? selectedItem.text || selectedItem.name || selectedItem.label
        : ''
    "
  >
    {{
      selectedItem
        ? selectedItem.text || selectedItem.name || selectedItem.label
        : placeholder
    }}
  </span>
  <div
    v-else
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click.prevent.stop="$emit('click')"
  >
    <MInput
      read-only
      :disabled="disabled"
      :size="size"
      :value="
        selectedItem
          ? selectedItem.text || selectedItem.name || selectedItem.label
          : undefined
      "
      :placeholder="placeholder"
      class="dropdown-trigger-input"
      :class="[{ 'dropdown-open': isOpen }, inputClasses]"
      @focus="handleFocus"
    >
      <FlotoDot
        v-if="selectedItem && selectedItem.color"
        slot="prefix"
        :bg="selectedItem.color"
      />
      <MIcon
        v-if="isActiveHover && allowClear && !disabled"
        slot="suffix"
        type="fas"
        name="times-circle"
        size="sm"
        class="cursor-pointer"
        @click.stop="$emit('reset', undefined)"
      />
      <MIcon
        v-else
        slot="suffix"
        type="fal"
        name="chevron-down"
        size="sm"
        class="cursor-pointer text-neutral-light"
      />
    </MInput>
    <slot name="bellow-input" />
  </div>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { getTagStyleFromColor } from '@utils/color'

export default {
  name: 'DropdownTrigger',
  props: {
    selectedItem: { type: [Object], default: undefined },
    allowClear: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    toggle: { type: Function, required: true },
    asInput: { type: Boolean, default: false },
    // eslint-disable-next-line
    size: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    isOpen: { type: Boolean, default: false },
    inputClasses: { type: [String, Object, Array], default: undefined },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    return {
      isActiveHover: false,
      isHovering: false,
    }
  },
  computed: {
    ...PreferenceComputed,
    currentStyle() {
      if (this.selectedItem) {
        return getTagStyleFromColor(this.selectedItem.color, this.theme)
      }
      return {}
    },
  },
  methods: {
    handleMouseEnter() {
      this.isHovering = true
      if (this.selectedItem) {
        this.isActiveHover = true
      }
    },
    handleMouseLeave() {
      this.isActiveHover = false
      this.isHovering = false
    },
    handleFocus(e) {
      if (this.focusEventBrodcast && !this.isHovering) {
        e.target.click()
        // this.toggle()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
