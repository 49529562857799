<template>
  <FlotoDropdownPicker
    v-if="!disabled && !onlyText"
    :value="value"
    :multiple="multiple"
    :options="timezoneOptions"
    as-input
    immediate-search
    searchable
    :placeholder="placeholder"
    @change="handleChange"
  />
  <span v-else class="text-ellipsis">
    {{ (selectedItem || {}).text }}
  </span>
</template>
<script>
import { TimezoneComputed } from '@state/modules/timezone'

export default {
  name: 'FlotoTimeZonePicker',
  model: {
    event: 'change',
  },
  props: {
    value: { type: String, default: undefined },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    onlyText: { type: Boolean, default: false },
    placeholder: { type: String, default: undefined },
  },
  computed: {
    ...TimezoneComputed,
    selectedItem() {
      if (this.value) {
        return this.timezoneOptions.find((t) => t.key === this.value)
      }
      return {}
    },
  },
  methods: {
    handleChange(value) {
      if (value === undefined || value === '') {
        this.$emit('change', undefined)
      }
      this.$emit('change', value)
    },
  },
}
</script>
