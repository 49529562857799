<script>
import { getSoftwareLicenseTypesApi } from '@modules/asset-management/api/software-license-type-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'SoftwareLicenseTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      loadedSoftwareType: [],
      selectedEventName: 'softwareLicenseTypeSelected',
    }
  },
  computed: {
    options() {
      return this.loadedSoftwareType
    },
  },
  created() {
    getSoftwareLicenseTypesApi({ transform: false }).then(
      ({ items }) =>
        (this.loadedSoftwareType = items.map((t) => ({ ...t, key: t.id })))
    )
  },
}
</script>
