<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchManufacturer"
    :disabled="disabled"
    as-input
    :multiple="multiple"
    :placeholder="placeholder"
    :text-only="textOnly"
    :immediate-search="false"
    overlay-class-name="picker-overlay wide-picker-overlay"
    v-bind="$attrs"
    :transform-options-fn="transformManufacturer"
    @change="$emit('change', $event || unassignedValue)"
  >
    <template v-slot:no-data>
      <h5
        class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
      >
        <MIcon name="search" class="mr-2" />
        {{ $tc('type_to_search') }}
      </h5>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getManufacturerCatalogsApi } from '@modules/asset-management/api/manufacturer-catalog-api'
export default {
  name: 'ManufacturerPicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, Array, String], default: undefined },
    placeholder: { type: String, default: undefined },
    textOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    return {
      isDirty: false,
    }
  },
  // created() {
  //   this.$once('hook:beforeUpdate', () => {
  //     this.isDirty = true
  //   })
  // },
  methods: {
    transformManufacturer({ items }) {
      return items.map((m) => ({ text: m.text, key: m.id }))
    },
    // searchManufacturer(name, selectedName) {
    //   let selectedItemName = selectedName
    //   let p = Promise.resolve()
    //   if (this.value && !this.isDirty) {
    //     p = getManufacturerCatalogsApi({ id: this.value }).then((data) => {
    //       return (this.selectedValueOptions = data.items)
    //     })
    //   }
    //   return p
    //     .then(() => {
    //       return getManufacturerCatalogsApi(
    //         { displayName: name, selectedName: selectedItemName },
    //         25,
    //         0
    //       )
    //     })
    //     .then((data) => {
    //       const ids = this.selectedValueOptions.map((i) => i.id)
    //       return {
    //         items: [
    //           ...this.selectedValueOptions,
    //           ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
    //         ],
    //       }
    //     })
    // },
    searchManufacturer(name, selectedItems) {
      let selectedItemNames = selectedItems.map((i) => i.text)
      if (this.value && !this.isDirty) {
        let ids = Array.isArray(this.value) ? this.value : [this.value]
        return getManufacturerCatalogsApi({ ids }, ids.length || 10, 0, {
          archived: true,
        }).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return getManufacturerCatalogsApi(
        { displayName: name, selectedName: selectedItemNames },
        100,
        0
      ).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>
