<template>
  <div>
    <slot v-if="!showInput" name="trigger">
      <div
        class="flex items-center justify-between cursor-pointer"
        @click="handleShowInput"
      >
        <span v-if="textValue"> {{ formatedValue(value) | duration }} </span>
        <span v-else class="text-neutral-lighter">{{ $t('select') }}</span>
        <MIcon name="clock" />
      </div>
    </slot>
    <FlotoForm
      v-else
      layout="vertical"
      class="flex items-center"
      @submit="update"
    >
      <div class="flex w-full">
        <MInputGroup compact class="input-group-container">
          <div class="flex-1">
            <MInputNumber
              id="estimated-time-picker-input"
              v-model="textValue"
              :min="min"
              :max="max"
              :disabled="disabled"
              :precision="precision"
              :auto-focus="!asInput"
              :placeholder="$tc(currentType, 3)"
            />
          </div>
          <MSelect
            v-if="!hideUnitSelection && durationOptions.length > 1"
            v-model="currentType"
            :placeholder="$t('select')"
            :options="durationOptions"
            as-input
            :disabled="disabled"
            :dropdown-match-select-width="false"
            :allow-clear="false"
            class="duration-type"
            input-classes="rounded-tl-none rounded-bl-none"
            @change="$emit('update:timeUnit', $event)"
          >
          </MSelect>
          <div
            v-else-if="!hideUnitSelection"
            class="px-2 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none"
            style="display: inline-flex"
          >
            {{ durationOptions[0].text }}
          </div>
        </MInputGroup>
      </div>
      <template v-slot:submit="{ submit }">
        <div v-if="!asInput" class="flex ml-2">
          <a
            :title="$t('update')"
            href="javascript:;"
            class="text-secondary-green font-semibold hover:text-secondary-green"
            @click="submit"
          >
            <MIcon name="check" size="lg" />
          </a>
          <a
            href="javascript:;"
            :title="$t('cancel')"
            class="ml-3 text-secondary-red font-semibold hover:text-secondary-red"
            @click="showInput = false"
          >
            <MIcon name="times" size="lg" />
          </a>
        </div>
        <span v-else />
      </template>
    </FlotoForm>
  </div>
</template>

<script>
import Moment from 'moment'

export default {
  name: 'FlotoEstimatedTimePicker',
  model: {
    event: 'change',
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
    max: { type: Number, default: undefined },
    timeUnit: { type: String, default: undefined },
    hideUnitSelection: { type: Boolean, default: false },
    asInput: { type: Boolean, default: false },
    useAsDistanceRange: { type: Boolean, default: false },
    allowedUnits: {
      type: Array,
      default() {
        // return []
        return ['hours']
      },
    },
    additionalUnits: {
      type: Array,
      default() {
        return []
      },
    },
    precision: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      textValue: undefined,
      currentType: this.timeUnit || 'hours' || 'miles',
      showInput: this.asInput,
    }
  },
  computed: {
    durationOptions() {
      let allOptions = [
        { text: this.$tc('minutes', 3), value: 'minutes', key: 'minutes' },
        { text: this.$tc('hours', 3), value: 'hours', key: 'hours' },
        { text: this.$tc('days', 3), value: 'days', key: 'days' },
        { text: this.$tc('months', 3), value: 'months', key: 'months' },
      ].concat(this.additionalUnits)
      if (this.allowedUnits.length) {
        allOptions = allOptions.filter(
          ({ value }) => this.allowedUnits.indexOf(value) >= 0
        )
      }
      return allOptions
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.textValue = this.convertValueForText()
      },
    },
    currentType() {
      if (this.asInput) {
        this.$nextTick(() => this.update())
      }
    },
    textValue(newValue, oldValue) {
      if (this.asInput && newValue !== oldValue) {
        this.$nextTick(() => this.update())
      }
    },
  },
  created() {
    if (this.formatedValue(this.value) < this.min) {
      this.$emit('change', this.min)
    }
  },
  methods: {
    formatedValue(value) {
      return value ? +value : 0
    },
    handleShowInput() {
      if (this.disabled) {
        return
      }
      this.showInput = true
    },
    convertValueForText() {
      const formatedValue = this.formatedValue(this.value)
      if (this.timeUnit) {
        return formatedValue
      }
      if (formatedValue) {
        const m = Moment.duration(formatedValue, 'milliseconds')
        return m.asHours()
        //   if (m.asMinutes() < 60) {
        //     this.currentType = 'minutes'
        //     return m.asMinutes()
        //   }
        //   if (m.asDays() >= 1) {
        //     this.currentType = 'days'
        //     if (m.hours() <= 0) {
        //       return m.asDays()
        //     }
        //     this.currentType = 'hours'
        //     return m.asHours()
        //   }
        //   this.currentType = 'hours'
        //   return m.asHours()
      }
      return this.min
    },
    update() {
      const v = this.precision
        ? parseFloat(this.textValue)
        : parseInt(this.textValue)
      if (v === 0 || isNaN(v)) {
        this.$emit('change', 0)
      } else {
        const m = Moment.duration(v, this.currentType)
        this.$emit('change', this.timeUnit ? v : m.asMilliseconds())
      }
      if (!this.asInput) {
        this.showInput = false
      }
    },
    reset() {
      this.showInput = false
      this.textValue = this.convertValueForText()
    },
  },
}
</script>

<style lang="less" scoped>
.input-group-container {
  top: 0 !important;
  display: flex !important;
  .@{ant-prefix}-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>

<style lang="less">
.input-group-container {
  .duration-type {
    .@{ant-prefix}-select-selection {
      color: var(--page-text-color) !important;
      background-color: var(--page-background-color) !important;
      border-color: var(--border-color);
    }
  }
}
.@{ant-prefix}-select-dropdown-menu-item {
  background-color: var(--dropdown-background);
}
.@{ant-prefix}-select-dropdown-menu-item:hover {
  color: var(--primary);
  background-color: var(--dropdown-hover-bg) !important;
}
</style>
