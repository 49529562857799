<script>
import { getTemplatesApi } from '@modules/templates/templates-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoTemplatePicker',
  extends: _baseDropdownPicker,
  props: {
    moduleName: { type: String, default: undefined, required: true },
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    overlayClassName: {
      type: String,
      default: 'picker-overlay wide-picker-overlay',
    },
  },
  data() {
    return {
      loadedTemplates: [],
      selectedEventName: 'templateSelected',
    }
  },
  computed: {
    options() {
      return this.loadedTemplates
    },
  },
  created() {
    getTemplatesApi({ moduleName: this.moduleName, transform: false }).then(
      ({ items }) =>
        (this.loadedTemplates = items.map((t) => ({ ...t, key: t.id })))
    )
  },
}
</script>
