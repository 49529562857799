<template>
  <FlotoCrudContainer
    :default-item="defaultNotification"
    :fetch-fn="getNotifications"
    :scrollable="false"
    :create-fn="addNotification"
    :show-no-data="false"
  >
    <template v-slot:add-controls="{ create }">
      <Component
        :is="stickySearch ? 'MAffix' : 'div'"
        v-if="!disabled"
        :offset-top="offsetTop"
        @change="searchAffixed = $event"
      >
        <div :class="{ 'pt-2': searchAffixed }" class="sticky-tab-header">
          <MRow>
            <MCol class="m-align-button">
              <MButton id="send-email-btn" variant="neutral" @click="create">
                {{ $t('send') }} {{ $tc('email_plural') }}
              </MButton>
            </MCol>
          </MRow>
          <MDivider />
        </div>
      </Component>
    </template>
    <template v-slot:form-header>
      {{ $t('send') }} {{ $tc('email_plural') }}
    </template>
    <template v-slot:form-items="{ item: notification }">
      <EmailNotificationForm
        :notification="notification"
        :module-name="moduleName"
      />
    </template>
    <template v-slot:form-actions="{ submit, cancel, processing }">
      <MButton id="send-btn" class="mx-1" :loading="processing" @click="submit">
        {{ $t('send') }}
      </MButton>
      <MButton id="cancel-btn" variant="default" @click="cancel">
        {{ $t('cancel') }}
      </MButton>
    </template>
    <template v-slot:item="{ item }">
      <EmailNotification :key="item.id" :notification="item" />
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import EmailNotificationForm from './email-notification-form'
import EmailNotification from './email-notification'
import { getNotificationApi, createNotificationApi } from './api'

export default {
  name: 'EmailNotificationContainer',
  components: { EmailNotificationForm, EmailNotification },
  props: {
    resource: { type: Object, required: true },
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    watcher: {
      type: Object,
      default() {
        return {}
      },
    },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
    defaultOutgoingEmailId: { type: Number, default: 0 },
  },
  data() {
    return {
      searchAffixed: false,
      defaultNotification: {
        subject: `[${this.resource.name}]: ${this.resource.subject}`,
        emailSendConfigId: this.defaultOutgoingEmailId,
        ...this.watcher,
      },
      defaultOutgoingEmail: {},
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    watcher: {
      handler(newValue) {
        this.defaultNotification = {
          subject: `[${this.resource.name}]: ${this.resource.subject}`,
          emailSendConfigId: this.defaultOutgoingEmailId,
          ...newValue,
        }
      },
    },
  },
  created() {
    if (this.user.signature) {
      this.defaultNotification = {
        ...this.defaultNotification,
        content: `<br />${this.user.signature}`,
      }
    }
  },
  methods: {
    getNotifications(limit, offset) {
      return getNotificationApi(this.moduleName, this.resourceId, limit, offset)
    },
    addNotification(data) {
      return createNotificationApi(this.moduleName, this.resourceId, {
        ...data,
      })
    },
  },
}
</script>
