<script>
import SortBy from 'lodash/sortBy'
import { CategoryComputed } from '@state/modules/category'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { searchCategoriesApi } from '../../modules/category/category-api'
import { buildRelationalQualificationStructure } from '../../data/qualification'

export default {
  name: 'FlotoServiceCategoryPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...CategoryComputed,
    options() {
      const list = this.serviceCatalogCategories
      const options = SortBy(list, 'order')
      return options
        .map((c) => ({
          text: c.name,
          label: c.name,
          key: c.id,
          id: c.id,
        }))
        .concat(this.archivedOptions)
    },
  },
  created() {
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const allAvailableUserIds = options.map((u) => u.id)
      const missingCategories = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1
      )
      if (missingCategories.length) {
        this.fetchMissingCategories(missingCategories)
      }
    }
  },
  methods: {
    fetchMissingCategories(missingCategories) {
      searchCategoriesApi(
        'service_catalog',
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              missingCategories,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            text: c.name,
            label: c.name,
            key: c.id,
            id: c.id,
            disabled: c.archived || c.disabled,
          }))
        )
      })
    },
  },
}
</script>
