<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchComputerGroup"
    :disabled="disabled"
    as-input
    :multiple="multiple"
    :placeholder="placeholder"
    :text-only="textOnly"
    :immediate-search="false"
    v-bind="$attrs"
    :transform-options-fn="transformComputerGroup"
    @change="$emit('change', $event || unassignedValue)"
  >
    <template v-slot:no-data>
      <h5
        class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
      >
        <MIcon name="search" class="mr-2" />
        {{ $tc('type_to_search') }}
      </h5>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getComputerGroupsApi } from '@modules/patch-management/patch-management-api'
export default {
  name: 'ComputerGroupPicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, Array, String], default: undefined },
    placeholder: { type: String, default: undefined },
    textOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line
    multiple: { type: Boolean, default: true },
    additionaParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    return {
      isDirty: false,
    }
  },
  methods: {
    transformComputerGroup({ items }) {
      return items.map((m) => ({ ...m, key: m.id }))
    },
    searchComputerGroup(name, selectedItems) {
      let selectedItemNames = selectedItems.map((i) => i.text)
      if (this.value && !this.isDirty) {
        let ids = Array.isArray(this.value) ? this.value : [this.value]
        return getComputerGroupsApi({ ids }, ids.length || 10, 0, {
          archived: true,
          ...this.additionaParams,
        }).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return getComputerGroupsApi(
        { name, selectedName: selectedItemNames },
        100,
        0,
        {
          ...this.additionaParams,
        }
      ).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>
