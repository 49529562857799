<template>
  <FlotoConfirmModal
    :open="showModal"
    prevent-auto-close-on-confirm
    :processing="processing"
    @hide="handleHide"
    @confirm="handleConfirmAction"
  >
    <template v-slot:icon>
      <MIcon name="info-circle" class="w-full text-primary" size="2x" />
    </template>
    <template v-slot:message>
      {{
        $t('confirm_update', {
          resource: '',
        })
      }}
    </template>
  </FlotoConfirmModal>
</template>

<script>
import Intersection from 'lodash/intersection'
import CloneDeep from 'lodash/cloneDeep'
import { CustomRulesComputed } from '@state/modules/custom-rules'
export default {
  name: 'DialogRulesConfirmModal',
  props: {
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      showModal: false,
      processing: false,
    }
  },
  computed: {
    ...CustomRulesComputed,
  },
  methods: {
    handleConfirmAction() {
      this.processing = true
      this.$emit('confirm')
    },
    handleHide() {
      this.showModal = false
      this.processing = false
    },
    handleShow() {
      this.showModal = true
      this.processing = false
    },
    handleCheckUpdatedFieldAvailableInDialogField(fields, change, resource) {
      let isUpdated = false
      fields.forEach((field) => {
        if (field in change && change[field] !== resource[field]) {
          isUpdated = true
          return isUpdated
        }
      })
      return isUpdated
    },
    checkIsDialogRulesQualifide(change, fields) {
      let dialogFields = CloneDeep(this[`${this.moduleName}DialogFields`] || [])
      if (fields) {
        dialogFields = fields
      }
      dialogFields = dialogFields.map((i) => `${i}`)
      if (Intersection(dialogFields, Object.keys(change)).length) {
        return true
      }
      return false
    },
  },
}
</script>
