<script>
import { getOutgoingEmailConfigsApi } from '@modules/it-infrastructure/it-infrastructure-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'OutgoingMailPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: false },
    onlyEnabledOptions: { type: Boolean, default: false },
  },
  data() {
    return {
      loadedOptions: [],
    }
  },
  computed: {
    options() {
      return this.loadedOptions
    },
  },
  created() {
    getOutgoingEmailConfigsApi().then(({ items }) => {
      if (this.value) {
        const value = Array.isArray(this.value) ? this.value : [this.value]

        const exists = items.filter((v) => value.indexOf(v.id) !== -1)
        if (exists.length <= 0) {
          this.$emit('change', 0)
        }
      }
      this.loadedOptions = items.map((i) => ({ ...i, key: i.id }))
      if (this.onlyEnabledOptions) {
        return (this.loadedOptions = this.loadedOptions.filter(
          (e) => e.enabled
        ))
      } else {
        return this.loadedOptions
      }
    })
  },
}
</script>
