<script>
export default {
  name: 'DraggableElement',
  props: {
    containerElement: {
      type: Object,
      default: undefined,
    },
    dragHandleSelector: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    boundingElement() {
      return (
        this.containerElement || {
          offsetHeight: window.innerHeight,
          offsetWidth: window.innerWidth,
        }
      )
    },
  },
  mounted() {
    const targetElement = this.getTargetElement()
    targetElement.addEventListener('mousedown', this.startMoving)
    document.addEventListener('mouseup', this.stopMoving)
  },
  beforeDestroy() {
    const targetElement = this.getTargetElement()
    targetElement.removeEventListener('mousedown', this.startMoving)
    document.removeEventListener('mouseup', this.stopMoving)
  },
  methods: {
    getTargetElement() {
      let element = this.$el
      if (this.dragHandleSelector) {
        const dragHandle = element.querySelector(this.dragHandleSelector)
        if (dragHandle) {
          element = dragHandle
        }
      }
      return element
    },
    move(x, y) {
      if (this.isDragging) {
        this.$el.style.left = `${x}px`
        this.$el.style.top = `${y}px`
      }
    },
    startMoving(evt) {
      this.isDragging = true
      const computedStyle = getComputedStyle(this.$el)
      evt = evt || window.event
      const posX = evt.clientX
      const posY = evt.clientY
      let divTop = parseInt(computedStyle.top)
      let divLeft = parseInt(computedStyle.left)
      const eWi = parseInt(computedStyle.width)
      const eHe = parseInt(computedStyle.height)
      const cWi = parseInt(this.boundingElement.offsetWidth)
      const cHe = parseInt(this.boundingElement.offsetHeight)
      document.body.style.cursor = 'move'
      const diffX = posX - divLeft
      const diffY = posY - divTop
      this.mouseMoveHandler = (evt) => {
        evt = evt || window.event
        const posX = evt.clientX
        const posY = evt.clientY
        let aX = posX - diffX
        let aY = posY - diffY
        if (aX < 0) aX = 0
        if (aY < 0) aY = 0
        if (aX + eWi > cWi) aX = cWi - eWi
        if (aY + eHe > cHe) aY = cHe - eHe
        this.move(aX, aY)
      }
      document.addEventListener('mousemove', this.mouseMoveHandler)
    },
    stopMoving() {
      this.isDragging = false
      document.body.style.cursor = 'default'
      document.removeEventListener('mousemove', this.mouseMoveHandler)
    },
  },
  render() {
    return this.$scopedSlots.default()
  },
}
</script>
