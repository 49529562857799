<script>
import DeepClone from 'lodash/cloneDeep'
import { assetConditionOptions } from '@modules/asset/helpers/option-map'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'AssetConditionPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(assetConditionOptions())
    },
  },
}
</script>
