<script>
import { SoftwareTypeComputed } from '@state/modules/software-type'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'SoftwareTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    this.selectedEventName = 'softwareTypeSelected'
    return {}
  },
  computed: {
    ...SoftwareTypeComputed,
    options() {
      return this.softwareTypeOptions
    },
  },
}
</script>
