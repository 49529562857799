<script>
import IsEqual from 'lodash/isEqual'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getDiscoverPollerConfigApi } from '@modules/asset-management/api/discovery-agents-api'

export default {
  name: 'PollerConfigPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    mandatory: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = null
    return {
      pollerConfigOptions: [],
    }
  },
  computed: {
    options() {
      return this.pollerConfigOptions
    },
  },
  watch: {
    options: {
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
  },
  created() {
    getDiscoverPollerConfigApi({}).then(({ items }) => {
      return (this.pollerConfigOptions = items.map((t) => ({
        ...t,
        text: t.text || t.name,
        key: t.id,
      })))
    })
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      if (!this.value && this.mandatory) {
        let defaultValue = this.options[0]
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
        }
      }
    },
  },
}
</script>
