<script>
import CloneDeep from 'lodash/cloneDeep'

export default {
  name: 'FlotoCrud',
  props: {
    pageSize: {
      type: Number,
      default: 25,
    },
    updateFn: {
      type: Function,
      default: null,
    },
    createFn: {
      type: Function,
      default: null,
    },
    deleteFn: {
      type: Function,
      default: null,
    },
    getFn: {
      type: Function,
      default: null,
    },
    defaultItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      currentActiveItem: null,
      currentEditingItem: null,
      processing: false,
    }
  },
  methods: {
    showForm(item = {}) {
      this.currentEditingItem = CloneDeep(
        Object.assign({}, this.defaultItem, item)
      )
    },
    hideForm() {
      this.$emit('form-hide')
      this.currentEditingItem = null
    },
    handleSubmit(item, fullItem) {
      if (!item) {
        throw new Error(
          'item is missing when form is submitted, please pass updated item to the submit function'
        )
      }
      if (item.id) {
        if (this.updateFn) {
          this.processing = true
          // @TODO add fullItme in second parameter if required
          return this.updateFn(item)
            .then((data) => {
              this.hideForm()
              return data
            })
            .finally(() => (this.processing = false))
        } else {
          throw new Error('update function is missing')
        }
      } else {
        if (this.createFn) {
          this.processing = true
          return this.createFn(item)
            .then((data) => {
              this.hideForm()
              return data
            })
            .finally(() => (this.processing = false))
        } else {
          throw new Error('create function is missing')
        }
      }
    },
    deleteItem(item) {
      if (this.deleteFn) {
        return this.deleteFn(item).then((data) => {
          if (this.currentActiveItem && this.currentActiveItem.id === item.id) {
            this.currentActiveItem = null
          }
          if (
            this.currentEditingItem &&
            this.currentEditingItem.id === item.id
          ) {
            this.currentEditingItem = null
          }
          return data
        })
      } else {
        throw new Error('delete function is missing')
      }
    },
    editItem(item) {
      if (!item) {
        throw new Error('editing item is missing')
      }
      if (this.getFn) {
        return this.getFn(item).then((data) => {
          this.showForm(data)
        })
      } else {
        this.showForm(item)
      }
    },
    markItemAsActive(item) {
      if (!item) {
        throw new Error('item to mark active is missing')
      }
      this.currentActiveItem = item
    },
    resetActiveItem() {
      this.currentActiveItem = null
    },
    getCurrentActiveItem() {
      return this.currentActiveItem
    },
    setProcessing(value) {
      this.processing = value
    },
    resetForm(item = {}) {
      this.currentEditingItem = CloneDeep(
        Object.assign({}, this.defaultItem, item)
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      activeItem: this.currentActiveItem,
      editingItem: this.currentEditingItem,
      activateItem: this.markItemAsActive,
      resetActiveItem: this.resetActiveItem,
      hideForm: this.hideForm,
      create: this.showForm,
      edit: this.editItem,
      submitForm: this.handleSubmit,
      remove: this.deleteItem,
      processing: this.processing,
      setProcessing: this.setProcessing,
      resetForm: this.resetForm,
    })
  },
}
</script>
