<template>
  <div class="flex cursor-pointer">
    <FlotoUserAvatar
      :size="size"
      class="mx-1"
      :avatar="useIcon ? undefined : avatar"
    />
    <span class="flex items-center">
      <slot name="availability-status" />
    </span>
    <span class="flex-1 flex items-center min-w-0">
      <MTooltip v-if="showTooltip">
        <template v-slot:trigger>
          <span class="text-ellipsis mx-1">
            {{ name }}
          </span>
        </template>
        {{ name }}
      </MTooltip>
      <span v-else class="text-ellipsis mx-1">
        {{ name }}
      </span>
    </span>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FlotoAvatarName',
  props: {
    size: { type: String, default: 'small' },
    avatar: { type: String, default: undefined },
    useIcon: { type: Boolean, default: false },
    name: { type: String, required: true },
    showTooltip: { type: Boolean, default: false },
  },
}
</script>
