<script>
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import projectStatus from '@modules/project/helpers/status'

export default {
  name: 'ProjectStatusPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    this.projectOptions = projectStatus()
    return {}
  },
  computed: {
    options() {
      return this.projectOptions
    },
  },
}
</script>
