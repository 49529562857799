<template>
  <FlotoForm ref="form" layout="vertical" @submit="handleSave">
    <template v-slot="slotData">
      <FlotoDrawer
        ref="drawer"
        :width="width"
        v-bind="$attrs"
        @show="$emit('show')"
        @hide="handleCancel"
      >
        <template v-slot:trigger="triggerSlotData">
          <slot name="trigger" v-bind="triggerSlotData" />
        </template>
        <template v-slot:title>
          <slot name="header"></slot>
        </template>
        <slot :submit="submit" />
        <template v-slot:actions="{ hide: hideDrawer }">
          <slot
            name="actions"
            v-bind="slotData"
            :hide="hideDrawer"
            :submit="submit"
          ></slot>
        </template>
      </FlotoDrawer>
    </template>
    <template v-slot:submit>
      <span />
    </template>
  </FlotoForm>
</template>

<script>
export default {
  name: 'FlotoDrawerForm',
  props: {
    open: { type: Boolean, default: false },
    width: { type: [String, Number], default: undefined },
  },
  watch: {
    open: {
      immediate: true,
      handler(newValue) {
        if (this.$refs.drawer) {
          if (newValue) {
            this.$refs.drawer.show()
          } else {
            this.$refs.drawer.hide()
          }
        }
      },
    },
  },
  mounted() {
    if (this.open) {
      this.$refs.drawer.show()
    }
  },
  methods: {
    submit() {
      this.$refs.form.submit()
    },
    handleSave() {
      this.$emit('submit')
    },
    handleCancel() {
      this.$emit('cancel')
    },
    hide() {
      this.$refs.drawer.hide()
    },
    show() {
      this.$refs.drawer.show()
    },
  },
}
</script>
