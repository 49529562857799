<script>
import IsEqual from 'lodash/isEqual'
import { ImpactComputed } from '@state/modules/impact'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoImpactPicker',
  extends: _baseDropdownPicker,
  props: {
    mandatory: { type: Boolean, default: false },
  },
  computed: {
    ...ImpactComputed,
    options() {
      return this.impactOptions
    },
  },
  watch: {
    options: {
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
  },
  created() {
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      if (!this.multiple && !this.value && this.mandatory) {
        let defaultValue = this.options.find((v) => v.default)
        if (!defaultValue) {
          defaultValue = this.options[0]
        }
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
          this.$emit('set-default-value', defaultValue.key)
        }
      }
    },
  },
}
</script>
