<script>
import {
  ProductTypeComputed,
  ProductTypeMethods,
} from '@state/modules/product-type'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoProductTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {}
  },
  computed: {
    ...ProductTypeComputed,
    options() {
      return this.productTypeOptions
    },
  },
  created() {
    if (!this.isProductTypeLoaded) {
      this.fetchProductTypes()
    }
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const allAvailableUserIds = options.map((u) => u.key || u.id)
      const missingItems = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1
      )
      if (missingItems.length) {
        this.$emit('change', this.unassignedValue)
      }
      // if (!this.isProductTypeLoaded) {
      //   this.fetchProductTypes()
      // }
    }
  },
  methods: {
    ...ProductTypeMethods,
  },
}
</script>
