<template>
  <div class="flex flex-1">
    <div class="items-center justify-center w-2/5 pr-2">
      <FlotoDropdownPicker
        v-model="dateType"
        as-input
        :get-popup-container="$attrs['get-popup-container']"
        :allow-clear="false"
        :options="dateTypeOptions"
      />
    </div>
    <FlotoDatePicker
      v-if="dateType === 'custom'"
      :value="value"
      class="flex-1"
      :show-time="showTime"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: 'DatetimeValueInput',
  model: {
    event: 'change',
  },
  props: {
    value: { type: [Number, String], default: undefined },
    showTime: { type: Boolean, default: false },
  },
  data() {
    return {
      localDateValue:
        this.value !== this.$constants.CURRENT_DATE_VALUE &&
        this.value !== this.$constants.CURRENT_DATE_TODAY
          ? 'custom'
          : 'current_date',
    }
  },
  computed: {
    dateType: {
      get() {
        return this.localDateValue
      },
      set(value) {
        if (value === 'current_date') {
          this.$emit('change', this.$constants.CURRENT_DATE_VALUE)
        } else {
          this.$emit('change', 0)
        }
        this.localDateValue = value
      },
    },
    dateTypeOptions() {
      return [
        {
          text: `${this.$tc('current')} ${this.$tc('date')} ${
            this.showTime ? this.$tc('time') : ''
          }`,
          key: 'current_date',
        },
        { text: this.$tc('custom'), key: 'custom' },
      ]
    },
  },
  watch: {
    value(newValue, preValue) {
      if (
        (preValue === this.$constants.CURRENT_DATE_VALUE ||
          preValue === this.$constants.CURRENT_DATE_TODAY) &&
        !newValue
      ) {
        this.localDateValue = 'custom'
      }
    },
  },
  methods: {
    handleChange(v) {
      this.$emit('change', v)
    },
  },
}
</script>
