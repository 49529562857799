<template>
  <div class="flex">
    <FlotoDropdownPicker
      class="flex-1"
      :value="timeType"
      :options="[...options, { text: $tc('custom'), key: 'custom' }]"
      v-bind="attrs"
      @change="handleValueChange"
      v-on="listeners"
    />
    <FlotoFormItem
      v-if="timeType === 'custom'"
      class="pl-4"
      rules="required"
      :validation-label="$t('value')"
    >
      <FlotoDatePicker
        v-model="fromValue"
        :show-time="inputType === 'dateTimeVariable'"
        :placeholder="$t('select')"
      />
    </FlotoFormItem>
  </div>
</template>

<script>
export default {
  name: 'DateVariablePicker',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: [Array, Number, String, Object, Boolean],
      default: undefined,
    },
    inputType: { type: String, default: undefined },
    valueType: { type: String, default: 'string' },
    directInput: { type: Boolean, default: false },
    options: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    let timeType
    if (this.value) {
      timeType = /^[a-z]/.test(this.value) ? this.value : 'custom'
    } else {
      timeType = ''
    }
    return {
      timeType,
      fromValue: this.value,
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
    listeners() {
      const { change, input, ...listeners } = this.$listeners
      listeners.selected = this.handleSelected
      return listeners
    },
    attrs() {
      return {
        'as-input': this.asInput,
        ...this.$attrs,
      }
    },
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.timeType = ''
      }
    },
    fromValue(newValue) {
      this.$emit('change', newValue)
    },
  },
  methods: {
    handleSelected(valueOption) {
      if (valueOption) {
        this.$emit('selected', valueOption)
      }
    },
    handleValueChange(value) {
      this.timeType = value
      if (this.timeType !== 'custom') {
        this.$emit('change', this.timeType)
      } else {
        const v = this.fromValue || undefined
        if (/^[a-z]/.test(v)) {
          this.fromValue = undefined
        }
        this.$emit('change', /^[a-z]/.test(v) ? undefined : v)
        this.$nextTick(() => {
          this.timeType = 'custom'
        })
      }
    },
  },
}
</script>
