<script>
import { getIncomingEmailConfigsApi } from '@modules/it-infrastructure/it-infrastructure-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'IncommingEmailConfigPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      list: [],
      selectedEventName: 'configSelected',
    }
  },
  computed: {
    options() {
      return this.list
    },
  },
  created() {
    getIncomingEmailConfigsApi().then(({ items }) => {
      if (this.value) {
        const value = Array.isArray(this.value) ? this.value : [this.value]

        const exists = items.filter((v) => value.indexOf(v.id) !== -1)
        if (exists.length <= 0) {
          this.$emit('change', 0)
        }
      }
      return (this.list = items.map((t) => ({ ...t, key: t.id })))
    })
  },
}
</script>
