<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchFn"
    :disabled="disabled"
    :as-input="asInput"
    :immediate-search="false"
    :transform-options-fn="transformManager"
    v-bind="$attrs"
    @change="$emit('change', $event || 0)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:no-data>
      <h5
        class="text-center mt-4 font-normal flex justify-center items-center text-neutral-light"
      >
        <MIcon name="search" class="mr-2" />
        {{ $tc('type_to_search') }}
      </h5>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getManagersApi, getGlobalUserApi } from '@modules/users/users-api'
export default {
  name: 'ManagerPicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, String, Array], default: undefined },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    userId: { type: Number, required: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      isDirty: false,
      selectedValueOptions: [],
    }
  },
  methods: {
    transformManager({ items }) {
      return items.map((t) => ({
        ...t,
        id: t.id,
        key: t.id,
        text: t.email ? `${t.name} (${t.email})` : t.name,
        archived: t.archived,
      }))
    },
    searchFn(name, selectedItems) {
      let selectedItemNames = selectedItems.map((i) => i.text)
      if (this.value && !this.isDirty) {
        return getGlobalUserApi(this.value, {
          archived: true,
        }).then((data) => {
          this.selectedValueOptions = [data]
          this.isDirty = true
          return { items: [data] }
        })
      }
      return getManagersApi(
        this.userId,
        {
          name,
          selectedName: selectedItemNames,
        },
        100,
        0
      ).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>
