<template>
  <FlotoDropdownPicker
    :disabled="disabled"
    :options="options"
    :value="value"
    v-bind="$attrs"
    @change="handleChange"
    v-on="listeners"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"> </slot>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import IsEqual from 'lodash/isEqual'
import { PriorityComputed } from '@state/modules/priority'
import FlotoDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoPriorityPicker',
  components: { FlotoDropdownPicker },
  model: {
    event: 'change',
  },
  props: {
    mandatory: { type: Boolean, default: false },
    value: { type: [Number, Array, String], default: undefined },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  // extends: _baseDropdownPicker,
  computed: {
    ...PriorityComputed,
    options() {
      return this.priorityOptions
    },
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
  },
  watch: {
    options: {
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
  },
  created() {
    this.setDefaultValue()
  },
  methods: {
    setDefaultValue() {
      if (!this.multiple && !this.value && this.mandatory) {
        let defaultValue = this.options.find((v) => v.default)
        if (!defaultValue) {
          defaultValue = this.options[0]
        }
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
          this.$emit('set-default-value', defaultValue.key)
        }
      }
    },
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
</script>
