<template>
  <div v-if="isShowAge">
    <MTooltip>
      <template v-slot:trigger>
        <ToolTipDeatils
          :is-couter-stopped="isCouterStopped"
          :pendding-in-status="penddingInStatus"
          :is-over-due="isOverDue"
          :diff="$options.filters.duration(diff, undefined, isHumanize)"
          :prefix="prefix"
          is-show-age
          class="text-ellipsis"
        />
      </template>
      <div class="flex flex-wrap">
        <ToolTipDeatils
          :is-couter-stopped="isCouterStopped"
          :pendding-in-status="penddingInStatus"
          :is-over-due="isOverDue"
          :diff="$options.filters.duration(diff, undefined, isHumanize)"
          is-show-age
          :prefix="prefix"
        >
          <slot name="after-text" :toggle="toggleDatepicker" />
        </ToolTipDeatils>
      </div>
    </MTooltip>
  </div>
  <div v-else-if="compactView">
    <MTooltip>
      <template v-slot:trigger>
        <MIcon
          name="clock"
          :size="iconSize"
          :class="{
            'text-secondary-green': !isCouterStopped && !isOverDue,
            'text-secondary-red': isOverDue,
            'text-neutral-light': isCouterStopped,
          }"
        />
      </template>
      <div class="flex flex-wrap">
        <ToolTipDeatils
          :is-couter-stopped="isCouterStopped"
          :pendding-in-status="penddingInStatus"
          :is-over-due="isOverDue"
          :diff="$options.filters.duration(diff, undefined, isHumanize)"
          :prefix="prefix"
        >
          <slot name="after-text" :toggle="toggleDatepicker" />
        </ToolTipDeatils>
      </div>
    </MTooltip>
  </div>
  <div
    v-else
    class="flex flex-1 items-center"
    :class="{
      'dropdown-trigger': asDropdown,
      'secondary-trigger': type === 'secondary',
      'mr-4': useMargin,
    }"
  >
    <div auto-size class="relative" :class="{ 'mx-1': !asDropdown }">
      <a
        :class="{ 'cursor-default': disabled, 'cursor-pointer': !disabled }"
        @click="toggleDatepicker"
      >
        <div
          :class="{
            'bg-secondary-green': asDropdown && !isCouterStopped && !isOverDue,
            'bg-secondary-red': asDropdown && isOverDue,
            'bg-neutral-light': asDropdown && isCouterStopped,
            'rounded icon-container': asDropdown,
            'p-2': asDropdown && type !== 'secondary',
          }"
        >
          <MIcon
            name="clock"
            :size="iconSize"
            :class="{
              'text-white': asDropdown,
              'text-secondary-green':
                !asDropdown && !isCouterStopped && !isOverDue,
              'text-secondary-red': !asDropdown && isOverDue,
              'text-neutral-light': !asDropdown && isCouterStopped,
            }"
          />
        </div>
      </a>
      <FlotoDatePicker
        v-if="isDatepickerOpen"
        default-open
        :value="value"
        class="due-date-picker"
        @hide="hideDatepicker"
        @change="handleDateChange"
      />
    </div>
    <div
      class="details flex flex-1 flex-col text-ellipsis py-1 mx-1"
      :style="{ lineHeight: '14px' }"
    >
      <div
        class="flex flex-wrap items-center"
        :class="{
          'text-secondary-green': asDropdown && !isCouterStopped && !isOverDue,
          'text-secondary-red': asDropdown && isOverDue,
          'text-neutral-light': asDropdown && isCouterStopped,
          'font-medium': asDropdown,
        }"
      >
        <slot name="before-text" :toggle="toggleDatepicker" />

        <div class="flex flex-1 text-ellipsis">
          <MTooltip>
            <template v-slot:trigger>
              <ToolTipDeatils
                :is-couter-stopped="isCouterStopped"
                :pendding-in-status="penddingInStatus"
                :is-over-due="isOverDue"
                :diff="$options.filters.duration(diff, undefined, isHumanize)"
                :prefix="prefix"
                class="text-ellipsis"
              />
              <!-- <span v-if="isCouterStopped">
                {{ $t('in') }} '{{ penddingInStatus }}' {{ $t('status') }}
                {{ $t('since') }}&nbsp;
              </span>
              <span v-else>
                {{ !isOverDue ? `${$tc(prefix)} ${$t('due_in')}` : '' }}
                {{
                  isOverDue ? `${$tc(prefix)} ${$t('overdue_by')}` : ''
                }}&nbsp;
              </span>
              <span>
                {{ /* pass value in duration filter for add prefix and sufix 'in' or 'ago' */ }}
                {{ diff | duration(undefined, isHumanize) }}
              </span> -->
            </template>
            <div class="flex flex-wrap">
              <ToolTipDeatils
                :is-couter-stopped="isCouterStopped"
                :pendding-in-status="penddingInStatus"
                :is-over-due="isOverDue"
                :diff="$options.filters.duration(diff, undefined, isHumanize)"
                :prefix="prefix"
              >
                <slot name="after-text" :toggle="toggleDatepicker" />
              </ToolTipDeatils>
            </div>
          </MTooltip>
        </div>
        <MTooltip v-if="!disabled && showIcon">
          <template v-slot:trigger>
            <MIcon
              name="pencil"
              class="cursor-pointer ml-2 text-neutral"
              @click="toggleDatepicker"
            />
          </template>
          {{ $t('edit') }}
        </MTooltip>
      </div>
      <slot name="after-text" :toggle="toggleDatepicker" />
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import { StatusComputed } from '@state/modules/status'

const ToolTipDeatils = {
  props: {
    isCouterStopped: { type: Boolean, required: true },
    penddingInStatus: { type: String, default: undefined },
    isOverDue: { type: Boolean, required: true },
    prefix: { type: String, required: true },
    diff: { type: [Number, String], required: true },
    isShowAge: { type: Boolean, default: false },
  },
  name: 'ToolTipDeatils',
  render() {
    return (
      <span>
        {this.isCouterStopped ? (
          <span>
            {this.$t('in')} '{this.penddingInStatus}' {this.$t('status')}&nbsp;
            {this.$t('since')}&nbsp;
          </span>
        ) : this.isShowAge ? (
          `${this.$tc(this.prefix)}`
        ) : (
          <span>
            {!this.isOverDue
              ? `${this.$tc(this.prefix)} ${this.$t('due_in')} `
              : ''}
            {this.isOverDue
              ? `${this.$tc(this.prefix)} ${this.$t('overdue_by')} `
              : ''}
          </span>
        )}
        <span>
          {this.diff}
          {this.$scopedSlots.default && this.$scopedSlots.default()}
        </span>
      </span>
    )
  },
}

export default {
  name: 'FlotoDueDatePicker',
  components: { ToolTipDeatils },
  model: {
    event: 'change',
  },
  props: {
    type: { type: String, default: undefined },
    value: { type: Number, required: true },
    statusId: { type: Number, default: undefined },
    moduleName: { type: String, required: true },
    iconSize: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    // eslint-disable-next-line
    showIcon: { type: Boolean, default: true },
    isHumanize: { type: Boolean, default: false },
    asDropdown: { type: Boolean, default: false },
    prefix: { type: String, default: '' },
    // eslint-disable-next-line
    useMargin: { type: Boolean, default: true },
    compactView: { type: Boolean, default: false },
    calculatedDiff: { type: [Number], default: undefined },
    isShowAge: { type: Boolean, default: false },
  },
  data() {
    this.currentInterval = null
    return {
      diff: 0,
      isDatepickerOpen: false,
    }
  },
  computed: {
    ...StatusComputed,
    isOverDue() {
      if (this.value > 0 && this.diff < 0) {
        return true
      }
      return false
    },
    isCouterStopped() {
      if (this.value < 0) {
        return true
      }
      return false
    },
    penddingInStatus() {
      return (
        (this[`${this.moduleName}Status`] || []).find(
          (s) => s.key === this.statusId
        ) || {}
      ).text
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.diff = this.getDiff()
      },
    },
  },
  created() {
    if (this.asDropdown) {
      this.currentInterval = setInterval(() => {
        this.diff = this.getDiff()
      }, 60000)
    }
  },
  beforeDestroy() {
    if (this.currentInterval) {
      clearInterval(this.currentInterval)
      this.currentInterval = null
    }
  },
  methods: {
    handleDateChange($event) {
      this.$emit('change', $event)
    },
    hideDatepicker() {
      // skip animation duration
      setTimeout(() => {
        this.isDatepickerOpen = false
      }, 400)
    },
    toggleDatepicker() {
      if (this.disabled) {
        return
      }
      this.isDatepickerOpen = !this.isDatepickerOpen
    },
    getDiff() {
      if (this.value === 0) {
        return 0
      }
      if (this.calculatedDiff) {
        return this.calculatedDiff
      }
      const actual = Moment.unix(Math.abs(this.value) / 1000)
      const today = Moment()
      const diff = actual.diff(today, 'millisecond')
      return diff
    },
  },
}
</script>

<style lang="less" scoped>
.due-date-picker {
  // position: relative;
  // top: 30px;
  // left: -30px;
  // width: 10px !important;
  // visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

.dropdown-trigger:not(.secondary-trigger) {
  color: var(--dropdown-trigger-color);
  background-color: var(--dropdown-trigger-bg-color);

  @apply rounded p-0;

  & .icon-container {
    @apply w-12 h-12 p-2 flex items-center justify-center;

    border-radius: 4px 0 0 4px;
  }

  & .lable-text {
    color: var(--neutral-dark);
  }
}
</style>
