<script>
import UniqBy from 'lodash/uniqBy'
import { getMessengerAppConfigApi } from '@src/modules/it-infrastructure/api/ai-bot-api.js'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'MessengerAppConfigPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      list: [],
      archivedOptions: [],
    }
  },
  computed: {
    options() {
      const options = this.list
      const filteredOptions = options.filter((c) => !c.archived)
      const value = Array.isArray(this.value) ? this.value : [this.value]
      const selectedOptions = value.length
        ? options.filter((o) => value.indexOf(o.id) >= 0)
        : []
      let avialableOptions = filteredOptions
      if (selectedOptions.length) {
        const availableIds = filteredOptions.map((f) => f.key)
        avialableOptions = avialableOptions.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.key) === -1)
        )
      }
      return UniqBy(avialableOptions.concat(this.archivedOptions), 'key')
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newValue) {
        if (this.value && newValue.length) {
          const v = Array.isArray(this.value) ? this.value : [this.value]
          const options = newValue
          const avialableOptions = this.$attrs['additional-options']
            ? (this.$attrs['additional-options'] || []).concat(options)
            : options
          const allAvailableServiceIds = avialableOptions.map((u) => u.key)
          const missingItems = v.filter(
            (id) => allAvailableServiceIds.indexOf(id) === -1
          )
          if (missingItems.length) {
            if (this.validateArchivedValue && !this.multiple) {
              this.$emit('change', this.unassignedValue)
            } else {
              this.fetchMissingItems(missingItems)
            }
          }
        }
      },
    },
  },
  created() {
    getMessengerAppConfigApi(undefined, undefined, {}).then(({ items }) => {
      this.list = items.map((m) => ({
        key: m.id,
        text: m.name,
        disabled: !m.enabled,
      }))
    })
  },
  methods: {
    fetchMissingItems(missingItems) {
      getMessengerAppConfigApi(undefined, undefined, { archived: true }).then(
        ({ items }) => {
          this.archivedOptions = Object.freeze(
            (items || []).map((c) => ({
              ...c,
              key: c.id,
              text: c.archived ? `${c.name} (${this.$tc('archived')})` : c.name,
              archived: true,
            }))
          )
        }
      )
    },
  },
}
</script>
