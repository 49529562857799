<template>
  <MRow>
    <MCol>
      <FlotoFormItem
        id="emails-picker"
        :label="$t('send_email_to', { receipent: '' })"
      >
        <FlotoTagsPicker v-model="notification.emails" type="email" />
      </FlotoFormItem>
      <MRow>
        <MCol :size="6">
          <FlotoFormItem
            id="technician-groups-picker"
            :label="$t('send_email_to', { receipent: $t('technician_group') })"
          >
            <FlotoTechnicianGroupPicker
              v-model="notification.technicianGroups"
              as-input
              multiple
            />
          </FlotoFormItem>
        </MCol>
        <MCol :size="6">
          <FlotoFormItem
            id="requester-groups-picker"
            :label="$t('send_email_to', { receipent: $t('requester_group') })"
          >
            <FlotoRequesterGroupPicker
              v-model="notification.requesterGroups"
              multiple
              as-input
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <FlotoFormItem
        id="subject-input"
        v-model="notification.subject"
        :label="$t('subject')"
        rules="required"
      />
      <MRow
        v-if="allowUserToSelectFromEmail && moduleName === $constants.REQUEST"
      >
        <MCol :size="6">
          <FlotoFormItem
            id="outgoing-mail-picker"
            :label="$tc('outgoing_email')"
            :placeholder="$tc('outgoing_email')"
            rules="nonzero"
          >
            <OutgoingMailPicker
              v-model="notification.emailSendConfigId"
              as-input
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <FlotoFormItem
        id="content-input"
        :label="`${$tc('email')} ${$t('content')}`"
        rules="required"
      >
        <FlotoRichEditor
          ref="editorRef"
          v-model="notification.content"
          :min-rows="8"
          :disallowed-extensions="['video']"
          :placeholder="`${$tc('email')} ${$t('content')}`"
        />
      </FlotoFormItem>
      <FlotoFormItem v-if="moduleName === $constants.PURCHASE">
        <MCheckbox v-model="notification.resourceAsAttachment">
          {{ `${$tc('add')} ${$tc('po')} ${$tc('as')} ${$tc('attachment')}` }}
        </MCheckbox>
      </FlotoFormItem>
      <FlotoFormItem id="attachments-input" :label="$tc('attachment')">
        <FlotoAttachment v-model="notification.attachments" />
      </FlotoFormItem>
    </MCol>
  </MRow>
</template>

<script>
import Pick from 'lodash/pick'
import { PreferenceComputed } from '@state/modules/preference'
import OutgoingMailPicker from '@components/data-picker/outgoing-mail-picker'
export default {
  name: 'EmailNotificationForm',
  components: { OutgoingMailPicker },
  props: {
    notification: { type: Object, required: true },
    moduleName: { type: String, default: undefined },
  },
  computed: {
    ...Pick(PreferenceComputed, ['allowUserToSelectFromEmail']),
  },
  mounted() {
    this.$refs.editorRef.focus('start')
  },
}
</script>
