<template>
  <div v-if="compactView" class="flex flex-1 items-center">
    <MTooltip>
      <template v-slot:trigger>
        <div class="flex items-center">
          <slot name="icon-container">
            <div
              v-if="iconName"
              class="flex items-center text-neutral"
              :style="
                applyColorInCompactView
                  ? {
                      color: currentItem.color,
                    }
                  : {}
              "
            >
              <slot name="icon">
                <CustomIcon v-if="customIcon" size="lg" :name="iconName" />
                <MIcon v-else :name="iconName" size="lg" />
              </slot>
            </div>
          </slot>
        </div>
      </template>
      {{ lable }}:
      {{ currentItem.lable || currentItem.name || currentItem.text }}
    </MTooltip>
  </div>
  <div
    v-else
    class="flex flex-1 dropdown-trigger items-center"
    :class="{
      'cursor-pointer': !disabled,
      'secondary-trigger': type === 'secondary',
      'mr-4': useMargin,
    }"
  >
    <slot name="icon-container">
      <div
        v-if="iconName"
        class="icon-container"
        :style="{
          backgroundColor: currentStyle.backgroundColor,
          color: currentStyle.color,
        }"
      >
        <slot name="icon">
          <CustomIcon v-if="customIcon" :name="iconName" />
          <MIcon
            v-else
            :name="iconName"
            :size="type === 'secondary' ? 'lg' : '2x'"
          />
        </slot>
      </div>
    </slot>
    <div class="details flex flex-1 flex-col text-ellipsis py-1 mx-1">
      <div
        v-if="lable"
        class="lable-text text-ellipsis"
        :class="{ 'field-lable-required': required }"
      >
        {{ lable }}
      </div>
      <div class="value-text text-ellipsis">
        {{ currentItem.lable || currentItem.name || currentItem.text }}
      </div>
    </div>
    <div v-if="!disabled" class="action-arrow">
      <CustomIcon name="downArrow" />
    </div>
  </div>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { getTagStyleFromColor } from '@utils/color'
import CustomIcon from '@components/custom-icon/custom-icon'
export default {
  name: 'FlotoDropdownTrigger',
  components: { CustomIcon },
  props: {
    currentItem: {
      type: Object,
      default() {
        return {}
      },
    },
    lable: { type: String, default: undefined },
    iconName: { type: String, default: undefined },
    toggle: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
    type: { type: String, default: undefined },
    customIcon: { type: Boolean, default: false },
    // eslint-disable-next-line
    useMargin: { type: Boolean, default: true },
    compactView: { type: Boolean, default: false },
    applyColorInCompactView: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  computed: {
    ...PreferenceComputed,
    currentStyle() {
      return getTagStyleFromColor(this.currentItem.color, this.theme) || {}
    },
  },
  watch: {
    'currentItem.color': {
      immediate: true,
      handler(newValue) {
        this.$emit('color', newValue)
      },
    },
  },
}
</script>
