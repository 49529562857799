<template>
  <FlotoScrollDropdown
    ref="dropdownRef"
    :options="availableColumns"
    @show="handleChangeSelectedColumn"
  >
    <template v-slot:trigger="triggerSlotData">
      <slot name="trigger" v-bind="triggerSlotData">
        <span @click="triggerSlotData.toggle">
          <MTooltip>
            <template v-slot:trigger>
              <MButton shape="circle" variant="neutral-lighter" :shadow="false">
                <MIcon name="table" />
              </MButton>
            </template>
            {{ $tc('kanban') }} {{ $tc('column') }} {{ $tc('selection') }}
          </MTooltip>
        </span>
      </slot>
    </template>

    <MRow class="m-2 p-2" :gutter="0">
      <MCol>
        <MInput
          v-model="searchTerm"
          class="search-box"
          auto-focus
          :placeholder="$t('search')"
        >
          <template v-slot:prefix>
            <MIcon name="search" />
          </template>
        </MInput>
        <div class="my-3 column-check-list">
          <div v-if="!searchTerm" class="ant-dropdown-menu-item">
            <MCheckbox
              :checked="selectedColumns.length === filteredColumns.length"
              @change="handleSelectAll"
            >
              {{ $tc('select') }} {{ $tc('all') }}
              {{ $tc('column', 2) }}
            </MCheckbox>
          </div>
          <MCheckboxGroup :value="selectedColumns" class="w-full">
            <MCol
              v-for="column in filteredColumns"
              :id="column.key"
              :key="column.key"
            >
              <div class="ant-dropdown-menu-item">
                <MCheckbox
                  id="field-checkbox"
                  :value="column.key"
                  @change="handleKanbanColumnChange(column, $event)"
                >
                  {{ column.text }}
                </MCheckbox>
              </div>
            </MCol>
          </MCheckboxGroup>
          <div v-if="(filteredColumns || []).length === 0">
            <FlotoNoData size="small" :col-size="12" />
          </div>
        </div>
      </MCol>
    </MRow>
    <MDivider class="my-0" />
    <MRow class="m-3 justify-end" :gutter="0">
      <MButton
        id="apply-btn"
        size="small"
        outline
        variant="primary"
        class="mx-2"
        :disabled="(selectedColumns || []).length <= 0"
        @click="handleApplyColumnSelection"
      >
        {{ $tc('apply') }}
      </MButton>
    </MRow>
  </FlotoScrollDropdown>
</template>
<script>
import Uniq from 'lodash/uniq'
import {
  PreferenceMethods,
  PreferenceComputed,
} from '@state/modules/preference'
export default {
  name: 'KanbanColumnSelector',
  model: {
    event: 'change',
  },
  props: {
    availableColumns: { type: Array, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      searchTerm: '',
      selectedColumns: [],
    }
  },
  computed: {
    ...PreferenceComputed,
    filteredColumns() {
      if (this.searchTerm) {
        return this.availableColumns.filter(
          (x) =>
            x.text.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
        )
      }
      return this.availableColumns
    },
  },
  created() {
    this.appliedColumnSelection()
  },
  methods: {
    ...PreferenceMethods,
    handleKanbanColumnChange(column, isChecked) {
      if (isChecked) {
        this.selectedColumns = Uniq([
          ...(this.selectedColumns || []),
          column.key,
        ])
      } else {
        this.selectedColumns = this.selectedColumns.filter(
          (c) => c !== column.key
        )
      }
    },
    appliedColumnSelection() {
      const defaultKanbanColumns = this.defaultKanbanStatusColumns(
        this.moduleName
      )
      if (defaultKanbanColumns.length > 0) {
        this.selectedColumns = this.defaultKanbanStatusColumns(this.moduleName)
      } else {
        this.selectedColumns = this.availableColumns.map((item) => item.key)
      }
    },
    handleSelectAll(event) {
      if (event === true) {
        this.selectedColumns = this.filteredColumns.map((i) => i.key)
      } else {
        this.selectedColumns = []
      }
    },
    handleApplyColumnSelection() {
      this.$refs.dropdownRef.hide()
      if ((this.selectedColumns || []).length) {
        this.updateModulePreference({
          moduleName: this.moduleName,
          kanbanStatusColumns: this.selectedColumns,
        }).then(() => {
          this.$emit('refresh')
        })
      } else {
        this.$emit('refresh')
      }
    },
    handleChangeSelectedColumn() {
      this.appliedColumnSelection()
    },
  },
}
</script>
<style scoped>
.ant-dropdown-menu-item {
  padding: 5px;
}

.column-check-list {
  max-height: 150px;
  overflow-y: scroll;
}
</style>
