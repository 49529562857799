<script>
import DeepClone from 'lodash/cloneDeep'
import { architectureOptions } from '@modules/computer/helpers/options-map'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'ArchitecturePicker',
  extends: _baseDropdownPicker,
  props: {
    hiddenOptions: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      if (this.hiddenOptions && this.hiddenOptions.length) {
        return DeepClone(
          architectureOptions().filter(
            (o) => this.hiddenOptions.indexOf(o.key) === -1
          )
        )
      }
      return DeepClone(architectureOptions())
    },
  },
}
</script>
