<template>
  <div class="flex flex-col flex-1">
    <MInputGroup compact style="display: flex">
      <div class="flex-1">
        <FlotoDropdownPicker
          :value="timeType"
          as-input
          :allow-clear="false"
          :options="displayOptions"
          @change="handleValueChange($event)"
        />
      </div>
      <div v-if="timeType === 'custom'" class="flex-1">
        <MInput v-model="formValue" :placeholder="`${$tc('format')}`" />
      </div>
    </MInputGroup>
    <span
      v-if="datePreview"
      class="font-thin font-italic mx-1 my-1 date-format-preview"
    >
      <b>{{ $tc('preview') }}:</b>
      {{ datePreview }}
    </span>
    <!-- <MMenu class="dropdown-picker-menu">
      <MMenuItem
        v-for="option in displayOptions"
        :key="option.value"
        class="date-format"
      >
        <MRadio
          :value="option.value"
          :checked="option.value === timeType"
          class="flex items-center"
          @click="handleValueChange(option.value)"
        >
          {{ option.text }}
          <small
            v-if="option.value !== 'custom'"
            class="font-thin font-italic mx-1 date-format-preview"
          >
            ({{ Moment().format(option.value) }})
          </small>
        </MRadio>
      </MMenuItem>
    </MMenu> -->
    <!-- <FlotoFormItem
      v-if="timeType === 'custom'"
      v-model="formValue"
      :validation-label="`${$tc('format')}`"
      :placeholder="`${$tc('format')}`"
    /> -->
  </div>
</template>

<script>
import Moment from 'moment'
import {
  dateTimeFormatOptions,
  dateFormatOptions,
  timeFormatOptions,
} from './option-map'
export default {
  name: 'FormatSelector',
  model: {
    event: 'change',
  },
  props: {
    format: { type: String, required: true },
    value: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  data() {
    this.Moment = Moment
    return {
      timeType: '',
      formValue: this.value,
    }
  },
  computed: {
    displayOptions() {
      return [
        ...(this.format === 'dateTime' ? dateTimeFormatOptions() : []),
        ...(this.format === 'date' ? dateFormatOptions() : []),
        ...(this.format === 'time' ? timeFormatOptions() : []),
        { text: this.$tc('custom'), key: 'custom', value: 'custom' },
      ]
    },
    datePreview() {
      let preview
      if (this.timeType && this.timeType !== 'custom') {
        preview = Moment().format(this.timeType)
      }
      if (this.timeType === 'custom' && this.formValue) {
        preview = Moment().format(this.formValue)
      }
      return preview
    },
  },
  watch: {
    formValue(newValue) {
      this.$emit('change', newValue)
    },
    timeType: {
      handler(newValue, prevValue) {
        if (prevValue && newValue !== prevValue && newValue === 'custom') {
          this.formValue = ''
        }
      },
    },
  },
  created() {
    if (this.value) {
      const timeType =
        this.displayOptions.map((o) => o.key).indexOf(this.value) >= 0
          ? this.value
          : 'custom'
      if (timeType === 'custom') {
        this.formValue = this.value
      }
      this.timeType = timeType
    }
  },
  methods: {
    handleValueChange($event) {
      this.timeType = $event
      if (this.timeType !== 'custom') {
        this.$emit('change', this.timeType)
      } else {
        const v = this.formValue || undefined
        // if (/^[a-z]/.test(v)) {
        //   this.formValue = undefined
        // }
        this.$emit('change', v)
      }
    },
  },
}
</script>

<style lang="less">
.date-format {
  height: unset !important;
  line-height: unset !important;
}

.date-format-preview {
  // font-size: 11px;
  // font-style: italic;
}
</style>
