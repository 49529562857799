<script>
import { FormComputed } from '@state/modules/form'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FeedbackPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    this.selectedEventName = 'feedbackSelected'
    return {}
  },
  computed: {
    ...FormComputed,
    systemAsCustonField() {
      return this.feedbackFields().find((f) => f.canDelete === false) || {}
    },
    options() {
      const feedbackRatings = this.systemAsCustonField.ratingScales.map(
        (t, index) => ({
          text: t.description,
          key: t.scaleNumber,
        })
      )
      return feedbackRatings
    },
  },
}
</script>
