<script>
import {
  ContractTypeComputed,
  ContractTypeMethods,
} from '@state/modules/contract-type'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'ContractTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    mandatory: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    return {}
  },
  computed: {
    ...ContractTypeComputed,
    options() {
      return this.contractTypeOptions
    },
  },
  watch: {
    isContractTypesLoaded: {
      immediate: true,
      handler: 'setDefaultValue',
    },
  },
  created() {
    if (!this.isContractTypesLoaded) {
      this.fetchContractTypes()
    } else {
      this.setDefaultValue()
    }
  },
  methods: {
    ...ContractTypeMethods,
    setDefaultValue() {
      if (this.isContractTypesLoaded && !this.value && this.mandatory) {
        const defaultValue = this.options[0]
        if (defaultValue) {
          this.$nextTick(() => {
            this.$emit('change', defaultValue.key)
          })
        }
      }
    },
  },
}
</script>
