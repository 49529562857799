<script>
import DeepClone from 'lodash/cloneDeep'
import { systemHealthSettingOptions } from '@modules/computer/helpers/options-map'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'SystemHealthPicker',
  extends: _baseDropdownPicker,
  data() {
    this.unassignedValue = null
    return {}
  },
  computed: {
    options() {
      return DeepClone(systemHealthSettingOptions())
    },
  },
}
</script>
