<script>
import { getManageBaselineApi } from '@modules/asset-management/api/manage-baseline-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'ManageBaselinePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    return {
      loadedOptions: [],
    }
  },
  computed: {
    options() {
      return this.loadedOptions
    },
  },
  created() {
    getManageBaselineApi({
      status: 'published',
    }).then(
      ({ items }) =>
        (this.loadedOptions = items.map((i) => ({
          ...i,
          key: i.id,
          text: i.displayName,
        })))
    )
  },
}
</script>
