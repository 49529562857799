<script>
import { RiskTypeComputed, RiskTypeMethods } from '@state/modules/risk-type'
import { buildRelationalQualificationStructure } from '@data/qualification'
import { getRiskTypesApi } from '@modules/change-management/change-management-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'FlotoRiskTypePicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    mandatory: { type: Boolean, default: false },
    validateArchivedValue: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = 0
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...RiskTypeComputed,
    options() {
      // @TODO when value is given and total is more than 1000 then we need to use search api to get items
      const options = this.riskTypeOptions
      const filteredOptions = options.filter((c) => !c.archived)
      const value = Array.isArray(this.value) ? this.value : [this.value]
      const selectedOptions = value.length
        ? options
            .filter((o) => value.indexOf(o.id) >= 0)
            .map((o) => ({ ...o, disabled: true }))
        : []
      let avialableOptions = filteredOptions
      if (selectedOptions.length) {
        const availableIds = filteredOptions.map((f) => f.id)
        avialableOptions = avialableOptions.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.id) === -1)
        )
      }
      return avialableOptions.concat(this.archivedOptions)
    },
  },
  watch: {
    isRiskTypeLoaded: {
      handler: 'setDefaultValue',
    },
  },
  created() {
    if (!this.isRiskTypeLoaded) {
      this.fetchRiskTypes()
    } else {
      this.setDefaultValue()
    }
    if (this.value) {
      const v = Array.isArray(this.value) ? this.value : [this.value]
      const options = this.options
      const avialableOptions = this.$attrs['additional-options']
        ? (this.$attrs['additional-options'] || []).concat(options)
        : options
      const allAvailableServiceIds = avialableOptions.map((u) => u.id)
      const missingItems = v.filter(
        (id) => allAvailableServiceIds.indexOf(id) === -1
      )
      if (missingItems.length) {
        if (this.validateArchivedValue && !this.multiple) {
          this.$emit('change', this.unassignedValue)
        } else {
          this.fetchMissingItems(missingItems)
        }
      }
    }
  },
  methods: {
    ...RiskTypeMethods,
    setDefaultValue() {
      if (this.isRiskTypeLoaded && !this.value && this.mandatory) {
        const defaultValue = this.options[0]
        if (defaultValue) {
          this.$nextTick(() => {
            this.$emit('change', defaultValue.key)
            this.$emit('set-default-value', defaultValue.key)
          })
        }
      }
    },
    fetchMissingItems(missingItems) {
      if (!this.isRiskTypeLoaded || !missingItems.length) {
        return
      }
      getRiskTypesApi(
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              missingItems,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            ...c,
            disabled: c.archived || c.disabled,
          }))
        )
      })
    },
  },
}
</script>
