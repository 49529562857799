<script>
import { getCostCentersApi } from '@modules/purchase-management/api/cost-center-api'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'

export default {
  name: 'CostCenterPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    return {
      loadedCostCenter: [],
      selectedEventName: 'costCenterSelected',
    }
  },
  computed: {
    options() {
      return this.loadedCostCenter
    },
  },
  created() {
    getCostCentersApi({ transform: false }).then(
      ({ items }) =>
        (this.loadedCostCenter = items.map((t) => ({ ...t, key: t.id })))
    )
  },
}
</script>
