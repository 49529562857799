<script>
import _baseDropdownPicker from '@components/_base-dropdown-picker.vue'
export default {
  name: 'PatchTypePicker',
  extends: _baseDropdownPicker,
  model: {
    event: 'change',
  },
  computed: {
    options() {
      return [
        { text: this.$tc('os_patch'), key: 'os_patch' },
        { text: this.$tc('third_party'), key: 'third_party' },
      ]
    },
  },
}
</script>
