<template>
  <FlotoDropdownPicker
    :value="value"
    :search-fn="searchAssetGroups"
    :disabled="disabled"
    :size="size"
    :multiple="multiple"
    :as-input="asInput"
    immediate-search
    :transform-options-fn="transformAssetGroup"
    overlay-class-name="picker-overlay wide-picker-overlay"
    v-bind="$attrs"
    @change="$emit('change', $event || 0)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData"> </slot>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getAssetGroupsApi } from '@modules/asset-management/api/asset-group-api'
import { customFieldAttributesModuleName } from '@src/modules/asset/helpers/asset-custom-fields'
export default {
  name: 'AssetGroupPicker',
  model: { event: 'change' },
  props: {
    value: { type: [Number, String, Array], default: undefined },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: undefined },
    multiple: { type: Boolean, default: false },
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    moduleName: {
      type: String,
      default() {
        return this.$constants.ASSET
      },
    },
    // eslint-disable-next-line
    keepUnassigned: { type: Boolean, default: true },
  },
  data() {
    this.unassignedValue = 0
    this.selectedValueOptions = []
    return {
      isDirty: false,
    }
  },
  computed: {
    derivedModuleName() {
      return customFieldAttributesModuleName(this.moduleName)
    },
  },
  methods: {
    transformAssetGroup({ items }) {
      return [
        ...(this.keepUnassigned
          ? [
              {
                text: this.$tc('unassigned'),
                key: 0,
                label: this.$tc('unassigned'),
                value: 0,
                id: 0,
              },
            ]
          : []),
      ].concat(
        items.map((f) => ({
          text: f.name,
          key: f.id,
          id: f.id,
          archived: f.archived,
        }))
      )
    },
    searchAssetGroups(name, selectedItems) {
      let selectedItemNames = selectedItems.map((i) => i.text)
      let excluded = []
      if (this.$attrs['additional-options']) {
        excluded = (this.$attrs['additional-options'] || []).map(
          (o) => o.text || o.name || o.label
        )
      }
      let excludedId = []
      if (this.$attrs['additional-options']) {
        excludedId = (this.$attrs['additional-options'] || []).map(
          (o) => o.key || o.id || o.value
        )
      }
      selectedItemNames = selectedItemNames.filter(
        (s) => excluded.indexOf(s.text) === -1
      )
      if (this.value && !this.isDirty) {
        let ids = Array.isArray(this.value) ? this.value : [this.value]
        if (excludedId.length) {
          ids = ids.filter((i) => excludedId.indexOf(i) === -1) || []
        }
        return getAssetGroupsApi(
          this.derivedModuleName,
          { ids },
          ids.length || 10,
          0,
          {
            archived: true,
          }
        ).then((data) => {
          this.selectedValueOptions = data.items
          this.isDirty = true
          return data
        })
      }
      return getAssetGroupsApi(this.derivedModuleName, {
        name,
        selectedName: selectedItemNames,
      }).then((data) => {
        const ids = this.selectedValueOptions.map((i) => i.id)
        return {
          items: [
            ...data.items.filter(({ id }) => ids.indexOf(id) === -1),
            ...this.selectedValueOptions,
          ],
        }
      })
    },
  },
}
</script>
