<template>
  <!-- remove :open="isOpen" property from MPopover for uncontrolled (hide, show) component  -->
  <MPopover
    ref="popover"
    overlay-class-name="picker-overlay keyword-selector"
    :placement="placement"
    @hide="handleHide"
  >
    <template v-slot:trigger="{ open: openModal }">
      <slot :show="openModal">
        <a class="keyword-trigger" @click="isOpen = true">
          <small>{{ triggerTex }}</small>
        </a>
      </slot>
    </template>
    <template v-slot:title>
      <div class="flex justify-between">
        <h4 class="m-0">{{ $tc('placeholder', 2) }}</h4>
        <MButton
          shape="circle"
          variant="transparent"
          :shadow="false"
          @click="hide"
        >
          <MIcon name="times" />
        </MButton>
      </div>
    </template>
    <MRow class="h-100">
      <MCol class="flex flex-col h-100">
        <div class="flex flex-col p-2">
          <MInput
            v-model="searchTerm"
            class="search-box"
            :placeholder="$t('search')"
          >
            <template v-slot:prefix>
              <MIcon name="search" />
            </template>
          </MInput>
        </div>
        <div class="flex-1 overflow-hidden">
          <div class="h-100 flex flex-col">
            <FlotoScrollView>
              <MCol
                v-for="(keywords, key) in currentKeywords"
                :key="key"
                :size="12"
                class="mb-4 p-2"
              >
                <h5 class="text-neutral-light mb-0">{{ key }}</h5>
                <MDivider class="mt-0" />
                <MTag
                  v-for="keyword in keywords"
                  :key="keyword.keyword"
                  rounded
                  variant="default"
                  class="mb-2"
                  :closable="false"
                  :title="keyword.name"
                  @click="handleKeywordSelected(keyword)"
                >
                  {{ keyword.name }}
                </MTag>
              </MCol>
              <FlotoNoData
                v-if="!(Object.keys(currentKeywords || {}) || []).length"
                size="small"
              />
            </FlotoScrollView>
          </div>
        </div>
      </MCol>
    </MRow>
    <template v-slot:footer="{ cancel }">
      <MButton @click="cancel">
        {{ $t('done') }}
      </MButton>
    </template>
  </MPopover>
</template>

<script>
import PickBy from 'lodash/pickBy'
import { searchList } from '@utils/arr'
import { KeywordMethods, KeywordComputed } from '@state/modules/keyword'

export default {
  name: 'KeywordSelector',
  props: {
    moduleName: { type: String, required: true },
    subModuleName: { type: String, default: undefined },
    placement: { type: String, required: true },
    isResponseTemplate: { type: Boolean, default: false },
    triggerTex: {
      type: String,
      default() {
        return `${this.$t('insert')} ${this.$tc('placeholder')}`
      },
    },
    availableKeywords: { type: Object, default: undefined },
    onlyEmailPlaceholder: { type: Boolean, default: false },
    onlyContactNoPlaceholder: { type: Boolean, default: false },
    autoClose: { type: Boolean, default: false },
    additionalUrlParams: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      searchTerm: '',
      isOpen: false,
    }
  },

  computed: {
    ...KeywordComputed,
    currentMouduleName() {
      if (this.subModuleName) {
        return this.subModuleName
      }
      return this.moduleName
    },
    currentKeywords() {
      let allKeywords = this[`${this.currentMouduleName}`]
      if (this.availableKeywords) {
        allKeywords = this.availableKeywords
      }
      // filter if searchTerm is defined
      if (this.searchTerm) {
        allKeywords = PickBy(allKeywords, (keywords) => {
          return searchList(keywords, this.searchTerm).length
        })
        Object.keys(allKeywords).forEach((key) => {
          allKeywords[key] = searchList(allKeywords[key], this.searchTerm)
        })
      }
      if (this.onlyEmailPlaceholder) {
        allKeywords = PickBy(allKeywords, (keywords) => {
          return searchList(keywords, 'TextFieldRest', 'customFieldType', 0)
            .length
        })
        Object.keys(allKeywords).forEach((key) => {
          allKeywords[key] = searchList(
            allKeywords[key],
            'TextFieldRest',
            'customFieldType',
            0
          )
        })
      }
      if (this.onlyContactNoPlaceholder) {
        allKeywords = PickBy(allKeywords, (keywords) => {
          return searchList(keywords, 'NumberFieldRest', 'customFieldType', 0)
            .length
        })
        Object.keys(allKeywords).forEach((key) => {
          allKeywords[key] = searchList(
            allKeywords[key],
            'NumberFieldRest',
            'customFieldType',
            0
          )
        })
      }
      return allKeywords
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(newValue) {
        this.$emit('is-open', newValue)
      },
    },
  },
  created() {
    if (this.availableKeywords) {
      return
    }
    if (
      this[`${this.currentMouduleName}`] &&
      this[`${this.currentMouduleName}Keywords`] &&
      this[`${this.currentMouduleName}Keywords`].length === 0
    ) {
      if (this.isResponseTemplate) {
        return this.responseTemplateKeywords()
      }
      return this[`${this.currentMouduleName}Keywords`](
        this.additionalUrlParams
      )
    }
  },
  methods: {
    ...KeywordMethods,
    show() {
      setTimeout(() => {
        this.$refs.popover.show()
      }, 250)
    },
    handleKeywordSelected(keyword) {
      this.$emit('selected', keyword)
    },
    handleHide() {
      if (this.autoClose) {
        this.handleClose()
      }
    },
    handleClose() {
      this.isOpen = false
      this.$emit('hide')
      this.searchTerm = ''
    },
    hide() {
      this.$refs.popover.hide()
      this.searchTerm = ''
    },
  },
}
</script>

<style lang="less">
.keyword-trigger {
  position: absolute;
  top: -11px;
  right: 2px;
  line-height: 0;
}
// .keyword-trigger-new {
//   position: relative;
//   top: 0;
//   left: 35rem;
// }
.keyword-popover {
  .@{ant-prefix}-popover-inner {
    display: flex;
    max-height: 400px;
  }
  .@{ant-prefix}-popover-inner-content {
    width: 500px;
    height: calc(100% - 37px); // 37px is height of title
    padding: 0;
  }
}
</style>
