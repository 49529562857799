<template>
  <MRow :gutter="0" class="items-center justify-center flex-1">
    <MCol :size="12">
      <!-- <MRow :gutter="0" class="justify-center">
        <MCol :size="colSize">
          <Error class="w-full" />
        </MCol>
      </MRow> -->
      <MRow :gutter="0">
        <MCol class="flex justify-center items-center flex-col">
          <component
            :is="$attrs.size === 'small' ? 'h5' : 'h1'"
            class="text-center font-normal flex justify-center items-center text-secondary-red"
            :class="{ 'mt-4': useMarginTop }"
          >
            <MIcon name="exclamation-circle" class="mr-2" />
            {{
              message ||
                $t(
                  authorizationError
                    ? 'authorization_error'
                    : 'error_loading_message'
                )
            }}
          </component>
          <slot v-if="!authorizationError" />
        </MCol>
      </MRow>
    </MCol>
  </MRow>
  <!-- <div class="flex flex-col items-center justify-center flex-1">
    <MDataError v-bind="$attrs">
      <slot />
    </MDataError>
  </div> -->
</template>

<script>
import Error from '@assets/images/error.svg'

export default {
  name: 'FlotoLoadError',
  components: { Error },
  props: {
    // colSize: { type: Number, default: 4 },
    authorizationError: { type: Boolean, default: false },
    // eslint-disable-next-line
    useMarginTop: { type: Boolean, default: true },
    message: { type: String, default: undefined },
  },
}
</script>
